import React from "react";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import Faq from "../Faq/Faq";

const data = {
  faqHeader: "Frequently Asked Questions - Hire Laravel Developer",
  caption:
    "Explore the details of hiring Laravel developers from our skilled team. Should you have additional inquiries or wish to discuss your project specifics, feel free to reach out to us.",
  faqContent: [
    {
      ques: "What skills should I look for when hiring a Laravel developer?",
      ans: "When hiring a Laravel developer, it's important to look for expertise in PHP programming language, proficiency in Laravel framework, including knowledge of MVC architecture, Eloquent ORM, Blade templating engine, and artisan command-line interface. Additionally, familiarity with database systems like MySQL or PostgreSQL, understanding of RESTful API development, and experience with version control systems such as Git are valuable.",
    },
    {
      ques: "Do you provide flexible engagement models for Laravel developers?",
      ans: "Yes, we offer flexible engagement models tailored to your project requirements. Whether you need a dedicated Laravel developer for a long-term project or prefer hourly-based arrangements for short-term tasks, we can accommodate your needs.",
    },
    {
      ques: "Can your Laravel developers work remotely?",
      ans: "Absolutely! Our Laravel developers are experienced in remote collaboration and can effectively work with your team regardless of geographical location. We utilize communication tools and project management systems to ensure seamless coordination and timely project delivery.",
    },
    {
      ques: "How do you ensure the quality of Laravel development?",
      ans: "Quality assurance is a priority in our Laravel development process. Our developers follow coding standards, conduct thorough testing using PHPUnit and other testing frameworks, adhere to Laravel best practices for security and performance optimization, and strive to deliver high-quality, scalable web applications that meet your business requirements.",
    },
  ],
};

export default function HireNodeDev() {
  return (
    <>
      <div
        className="inner-page"
        id="hire-us-bg"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Hire Node JS Developer</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line"></i>Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hire Node JS
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading row_gaping">
            <h2 className="section-title-2 text-center">
              Engage Node JS Experts at the Best rates
            </h2>
          </div>
          <div className="center-row row row-below g-2 row-cols-lg-6 row-cols-sm-3 row-cols-2">
            <div className="col-md text-center">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-1.png" alt="" />
                <h5>One Week Free Trial</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-2.png" alt="" />
                <h5>Minimum 3+ Years of Experience</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-3.png" alt="" />
                <h5>Non-Disclosure Agreement</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-4.png" alt="" />

                <h5>Flexible Hiring Models</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-5.png" alt="" />

                <h5>Competitive Rate</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-6.png" alt="" />
                <h5>Source Code Delivery</h5>
              </div>
            </div>
          </div>
          <div className="btn_sec text-center row-below">
            <Link to="/contact" className="btn2 btn_gap">
              Get Started
            </Link>
            <Link to="/clients" className="btn2">
              Our Clients
            </Link>
          </div>
        </div>
      </section>
      <section
        className="section hire_content_anguler"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="small-hire ">
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <div className="small_hire_content heading">
                <h3>WHAT CAN WE DO FOR YOU?</h3>
                <p>
                  We would be happy to discuss the project with you in person
                </p>
                <h4>
                  Call us on <Link to="tel:+18885054030">+1 888 505 4030</Link>
                </h4>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    DROP IN A MESSAGE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="section-title">
              Hire Offshore Node js Developers to Meet <br /> Custom
              Requirements
            </h2>
            <p className="section-subtitle pt-3">
              At Adret software, we value diversity and encourage developers
              from different backgrounds and locations to apply. Embrace the
              opportunity to work on cutting-edge projects in a collaborative
              and flexible remote environment.
            </p>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-7.png" alt="" />
                <h6 className="details-heading">Custom Node js Development</h6>
                <p>
                  Our team of skilled Node js developers is well-versed in
                  creating custom web applications that stand out in terms of
                  functionality, scalability, and user experience. Whether you
                  need a complete application built from scratch or enhancements
                  to your existing project, we have the expertise to deliver.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-8.png" alt="" />
                <h6 className="details-heading">API Integration</h6>
                <p className="">
                  Our experienced Node js developers can integrate custom APIs
                  into an application to extend its usability. From shipping to
                  payment gateways to calendars to live chat - we can integrate
                  APIs for a wide range of functionalities.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-9.png" alt="" />
                <h6 className="details-heading">Ajax Integration</h6>
                <p className="">
                  Elevate the interactivity and responsiveness of your web
                  applications with our Node.js Ajax integration services at
                  Adret software. Harness the power of asynchronous requests to
                  enhance user experiences and streamline data exchange between
                  your front-end and server..
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-10.png" alt="" />
                <h6 className="details-heading">Migration</h6>
                <p className="">
                  Hire remote Node js developers to migrate a web application to
                  the latest version of Node js. We adopt relevant migration
                  strategies to simplify the process. Our full-proof migration
                  approach prevents security issues and data loss.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white  req-card ">
                <img src="assets/image/hire-angular-icon-11.png" alt="" />
                <h6 className="details-heading">Support & Maintenance</h6>
                <p className="py-2">
                  Upgrade and future-proof your web applications with our expert
                  Node js migration services at Adret software. We specialize in
                  seamless transitions, ensuring your application stays current,
                  secure, and takes advantage of the latest Node js features.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-12.png" alt="" />
                <h6 className="details-heading">Ecommerce Development</h6>
                <p className="py-2">
                  Power your online business with our cutting-edge Node.js
                  Ecommerce Development services at Adret software. We
                  specialize in creating robust, scalable, and feature-rich
                  Ecommerce solutions that seamlessly integrate with your
                  business requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading  text-center">
            <h2>Why Choose Us</h2>
          </div>
          <div className="row g-3 ">
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-13.png" alt="" />
                </div>
                <h5 className="my-3">Industry Experts</h5>
                <p>
                  Our in-house team is enriched with trusted and experienced
                  developers - no freelancer.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-14.png" alt="" />
                </div>
                <h5 className="my-3">Widely recommended</h5>
                <p>
                  We got hundreds of real 5-star ratings and reviews on Google,
                  GoodFirms, and Clutch combined.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-15.png" alt="" />
                </div>
                <h5 className="my-3">Customer Service</h5>
                <p>
                  Our zero complaint work approach helps us to ensure a seamless
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-16.png" alt="" />
                </div>
                <h5 className="my-3">Ideal Workplace</h5>
                <p>
                  We cultivate a productive work environment with a trendy setup
                  to enhance the outcomes.
                </p>
              </div>
            </div>
            <div className="row-below btn_sec text-center">
              <Link to="/contact" className="btn2 btn_gap">
                Get Started
              </Link>
              <Link to="/clients" className="btn2">
                Our Clients
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Slider />

      <Faq data={data} />
    </>
  );
}
