import React from 'react'

export default function ToolsTechnology() {
  return (
    <>
    <section className="our_clientele_sec pt-0 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div
                className="heading row_gap"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Tools & Technologies </h2>
              </div>
            </div>
          </div>
          <div className="row row-cols-lg-6 g-2 row-cols-md-5 row-cols-sm-4 row-cols-3 justify-content-center align-items-center ">
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-1.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-2.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-3.jpg" className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-4.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-5.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-6.jpg" className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-7.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-8.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-9.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-10.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-11.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg">
              <div
                className="client_box"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src="assets/image/tools-12.jpg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
