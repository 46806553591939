import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/slidercss.css";

const testimonials = [
  {
    id: 1,
    name: "John Thompson",
    src: "assets/image/sliderImages/slider1.jpeg",
    rating: 4.5,
    company: "TechCom Solutions",
    designation: "Marketing Manager",
    content:
      "Working with Adret Software has been a game-changer for us. Their team's expertise and dedication helped us revolutionize our workflow and achieve remarkable efficiency gains. Highly recommended!",
  },
  {
    id: 2,
    name: "Alex Ramirez",
    src: "assets/image/sliderImages/slider2.jpeg",
    rating: 5,
    company: "BrightWave Technologies",
    designation: "CEO",
    content:
      "I'm continually impressed by the outstanding work of Adret Software. Their attention to detail and unwavering commitment to excellence have made every project a resounding success. Looking forward to future collaborations!",
  },
  {
    id: 3,
    name: "John Smith",
    src: "assets/image/sliderImages/slider3.jpeg",
    rating: 4,
    company: "Quantum Innovations",
    designation: "Digital Marketing Specialist",
    content:
      "Adret Software has been an invaluable partner for our business. Their innovative solutions and responsive team have significantly improved our digital presence and customer engagement. We're thrilled with the results!",
  },
  {
    id: 4,
    name: "Michael Brown",
    src: "assets/image/sliderImages/slider4.jpeg",
    rating: 4.5,
    company: "Fusion Systems",
    designation: "Project Manager",
    content:
      "I've had the pleasure of working with Adret Software on multiple projects, and they consistently deliver exceptional results. Their professionalism and expertise make them a trusted partner for any digital endeavor.",
  },
  {
    id: 5,
    name: "Samuel Davis",
    src: "assets/image/sliderImages/slider5.jpeg",
    rating: 5,
    company: "OptiTech Solutions",
    designation: "Lead Developer",
    content:
      "Adret Software exceeded all our expectations with their comprehensive approach to our project. Their attention to detail and commitment to quality were evident in every interaction. We're delighted with the outcome!",
  },
  {
    id: 6,
    name: "Matty Johnson",
    src: "assets/image/sliderImages/slider6.jpeg",
    rating: 4,
    company: "BlueSky Enterprises",
    designation: "Product Manager",
    content:
      "I highly recommend Adret Software to anyone seeking innovative solutions and top-notch service. Their team's expertise and dedication made our project a success, and we look forward to working with them again in the future.",
  },
  {
    id: 7,
    name: "Tom Martinez",
    src: "assets/image/sliderImages/slider7.jpeg",
    rating: 4.5,
    company: "NovaTech Corporation",
    designation: "Marketing Director",
    content:
      "Adret Software has been instrumental in enhancing our online presence. Their strategic approach to digital marketing has helped us reach new audiences and achieve our business goals. We're grateful for their partnership!",
  },
  {
    id: 8,
    name: "William Taylor",
    src: "assets/image/sliderImages/slider8.jpeg",
    rating: 5,
    company: "Stellar Innovations",
    designation: "Chief Technology Officer",
    content:
      "Working with Adret Software was a fantastic experience from start to finish. Their team was responsive, professional, and delivered exceptional results. We're thrilled with the impact their solutions have had on our business.",
  },
  {
    id: 9,
    name: "Sophia Rodriguez",
    src: "assets/image/sliderImages/slider9.jpeg",
    rating: 4.5,
    company: "Apex Solutions",
    designation: "Senior Analyst",
    content:
      "Adret Software's dedication to customer satisfaction is unmatched. They went above and beyond to understand our needs and deliver customized solutions that exceeded our expectations. Highly recommended!",
  },
  {
    id: 10,
    name: "Ethan White",
    src: "assets/image/sliderImages/slider10.jpeg",
    rating: 4,
    company: "Nexus Dynamics",
    designation: "Web Developer",
    content:
      "We've seen tremendous growth in our online presence since partnering with Adret Software. Their expertise in digital marketing and web development has been invaluable to our business. We couldn't be happier with the results!",
  },
];

const Slider = () => {
  return (
    <>
      <section className="testimonials_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div
                className="heading"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <h2>Testimonials From Clients </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <OwlCarousel
                items={3}
                loop
                margin={10}
                dots={false}
                nav
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
                className="owl-carousel owl-theme review_slider"
              >
                {testimonials.map((testimonial) => (
                  <div key={testimonial.id} className="item">
                    <div
                      className="testimonials_content"
                      data-aos="zoom-in"
                      data-aos-duration={2000}
                    >
                      <div className="contented">
                        <div className="top_content">
                          <div className="coma_sty">
                            <img
                              src="assets/image/home-new-quotetion.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: 9999,
                          }}
                          src={testimonial.src}
                          alt={testimonial.name}
                        />
                        <div className="testimonials-rating">
                          {Array.from(
                            { length: Math.floor(testimonial.rating) },
                            (_, index) => (
                              <i key={index} className="fa fa-star"></i>
                            )
                          )}
                          {testimonial.rating % 1 !== 0 && (
                            <i className="fa fa-star-half"></i>
                          )}
                        </div>
                        <p>{testimonial.content}</p>
                        <h4>- {testimonial.name}</h4>
                        <p className="m-0">
                          {testimonial.company},{testimonial.designation}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
