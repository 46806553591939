import React from "react";
import OurClient from "../../OurClient/OurClient";
import Faq from "../../Faq/Faq";
import { Link } from "react-router-dom";

const data = {
  faqHeader: "Frequently Asked Questions - Explainer Video Services",
  caption:
    "Explore the details of our explainer video services. If you have additional questions, feel free to reach out to our knowledgeable team.",
  faqContent: [
    {
      ques: "What Elements Constitute an Effective Explainer Video?",
      ans: "An effective explainer video combines engaging visuals, a clear and concise script, a captivating narrative, and professional audio elements. It aims to simplify complex concepts and deliver your message in a way that is easy to understand and memorable for your audience.",
    },
    {
      ques: "Why are Explainer Videos Important for Marketing?",
      ans: "Explainer videos play a crucial role in marketing by providing a dynamic and visually appealing way to communicate your brand, products, or services. They capture and maintain viewer attention, effectively conveying information and increasing audience engagement. Explainer videos are versatile and can be shared across various online platforms.",
    },
    {
      ques: "Do You Provide Custom Explainer Video Solutions?",
      ans: "Absolutely! Our explainer video services are tailored to meet your unique requirements. We collaborate with you to understand your brand, messaging, and target audience, creating custom explainer videos that effectively communicate your value proposition and resonate with your viewers.",
    },
    {
      ques: "How Can Explainer Videos Enhance Brand Visibility?",
      ans: "Explainer videos enhance brand visibility by presenting your brand or products in a visually compelling and shareable format. A well-crafted explainer video can go viral, reaching a wide audience and creating a memorable impression. It helps increase brand awareness and positions your business as an expert in your industry.",
    },
  ],
};


export default function ExplainerVideo() {
  return (
    <>
      <section className="logo_design_sec1 bg_color1">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-8 col-sm-9">
              <div
                className="heading"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <h1>Explainer Videos</h1>
                <p>
                  At Adret Software, we recognize the impact of compelling
                  explainer videos. In today's dynamic market, explainer videos
                  are more than just animations; they are a powerful
                  storytelling tool. We specialize in the art and science of
                  creating unique explainer videos that simplify complex ideas,
                  captivate audiences, and leave a lasting impression.
                </p>
                <div className="btn_sec ">
                  <Link to="/contact" className="btn1 btn_gap">
                    Quick Enquiry
                  </Link>
                  <Link to="/portfolio" className="btn3">
                    Watch our Work
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-3">
              <img
                src="assets/image/explainer-video-img.jpg"
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="2000"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_business">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Our Approach</h2>
                <p>
                  At Adret Software, our approach to explainer videos goes
                  beyond animation. We view them as strategic tools that
                  simplify complex concepts and engage your audience
                  effectively. Our mission is to transform your ideas into
                  visually compelling stories that resonate with your target
                  audience.
                </p>
                <p>
                  Whether you're introducing a new product, explaining a
                  process, or enhancing brand awareness, our explainer videos
                  are crafted to deliver your message with impact and clarity.
                </p>
                <div className="btn_sec">
                  <Link to="/contact" className="btn1 btn_gap">
                    Request a Quote
                  </Link>
                  <Link to="/pricing" className="btn3">
                    Our Packages
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_best">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Explainer Video Examples</h2>
                <p>
                  Explore our portfolio of impactful explainer videos. As a
                  leading provider in the industry, we specialize in creating
                  visually compelling and informative videos that resonate with
                  your audience.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <iframe
                width="100%"
                src="assets/image/adret-video.mp4"
                title="Adret intro video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                data-aos="zoom-out"
                data-aos-duration="2000"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_project p-0">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/logo-design-img1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="heading" data-aos="fade-left" data-aos-duration="2000">
              <h2>Ready to Discuss Your Explainer Video Project?</h2>
              <p>We would be happy to explore ideas and strategies with you.</p>
              <h4>
                Call To Us : <Link to="tel:+18885054030">+1 888 505 4030</Link>
              </h4>
              <div className="btn_sec">
                <Link to="/contact" className="btn2">
                  Drop in a message
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_sec">
        <div className="container">
          <div className="heading" data-aos="fade-up" data-aos-duration="2000">
            <h2>Why Choose Adret Software for Professional Explainer Videos</h2>
            <p>
              An impactful explainer video is a key asset in your marketing
              strategy. At Adret Software, a leading explainer video service
              provider, we craft videos that engage, educate, and elevate your
              brand. Our experienced team of animators and storytellers combines
              creativity with strategic insight to deliver explainer videos that
              make a lasting impression.
            </p>
          </div>
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Strategic Animation</h5>
                      <p>
                        Our explainer videos are strategically animated to
                        simplify complex concepts and engage your audience
                        effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Creative Storytelling</h5>
                      <p>
                        We go beyond visuals, focusing on creative storytelling
                        that captures attention and delivers your message with
                        impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Professional Editing</h5>
                      <p>
                        Our team ensures seamless and professional editing to
                        create polished explainer videos that reflect your
                        brand's quality.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Collaborative Process</h5>
                      <p>
                        We believe in collaboration, involving you in the
                        creative process to ensure the final video aligns with
                        your vision.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="btn_sec" data-aos="fade-up" data-aos-duration="3000">
                <a to="/" className="btn1">
                  Explore Explainer Video
                </a>
              </div> */}
            </div>
            <div className="col-lg-4">
              <img
                src="assets/image/explainer-video-img1.jpg"
                className="img-fluid  rounded"
                alt=""
                data-aos="zoom-out"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="portfolio_logo bg_color1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center row_gap">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Explore Our Explainer Video Showcase</h2>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 row-below text-center">
              <div className="btn_sec" data-aos="zoom-in" data-aos-duration="2000">
                <a to="" className="btn1">
                  See Full Portfolio
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <OurClient />
     <Faq data={data} />
    </>
  );
}
