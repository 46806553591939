import React from "react";
import { Link } from "react-router-dom";
import OurClient from "../../OurClient/OurClient";
import Faq from "../../Faq/Faq";

const data = {
  faqHeader: "Frequently Asked Questions - Brochure Design Services",
  caption:
    "Explore the details of our brochure design services. If you have additional questions, feel free to reach out to our knowledgeable team.",
  faqContent: [
    {
      ques: " What Elements Constitute an Effective Brochure Design?",
      ans: " An effective brochure design incorporates visually appealing graphics, concise and compelling content, a well-defined layout, and a strategic use of colors. It aims to captivate the audience's attention and effectively convey the intended message.",
    },
    {
      ques: "Why is Brochure Design Important for Marketing?",
      ans: "Brochure design plays a crucial role in marketing by providing a tangible, informative, and visually engaging medium for communicating with your audience. It serves as a versatile tool for conveying your brand story, products, and services.",
    },
    {
      ques: "Do You Provide Custom Brochure Design Solutions?",
      ans: "Absolutely! Our brochure design services are tailored to meet your unique requirements. We work collaboratively to understand your brand, objectives, and target audience, creating custom brochure designs that align with your brand identity and messaging.",
    },
    {
      ques: "  How Can Brochure Design Enhance Brand Visibility?",
      ans: " Brochure design contributes to brand visibility by creating a memorable and professional representation of your business. A well-designed brochure reinforces your brand identity, making it more recognizable and a lasting impression on your target audience.",
    },
  ],
};

export default function BrochureDesign() {
  return (
    <>
      <section className="logo_design_sec1 bg_color1">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-8 col-sm-9">
              <div
                className="heading"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <h1>Brochure Design</h1>
                <p>
                  At Adret Software, we believe in the power of visually
                  compelling brochures. Beyond just informational pieces,
                  brochures are a reflection of your brand's personality. We
                  specialize in creating captivating brochure designs that not
                  only communicate information effectively but also leave a
                  lasting impression on your audience.
                </p>
                <div className="btn_sec ">
                <a href="/contact" className="btn1 btn_gap">
                Quick Enquiry
              </a>
              <a href="/portfolio" className="btn3">
                Portfolio
              </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-3">
              <img
                src="assets/image/brochure-design.jpg"
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="2000"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_business">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Our Approach</h2>
                <p>
                  At Adret Software, our approach to brochure design goes beyond
                  aesthetics. We understand that a well-designed brochure is a
                  powerful marketing tool. Our mission is to create brochures
                  that not only showcase your products or services but also tell
                  a compelling story. We blend creativity with strategic
                  thinking to ensure that your brochure stands out and resonates
                  with your target audience.
                </p>
                <div className="btn_sec">
                  <a href="/contact" className="btn1 btn_gap">
                    Request a Quote
                  </a>
                  <a href="/pricing" className="btn3">
                    Explore Our Packages
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="logo_design_best">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Brochure Design Services - Captivate Your Audience</h2>
                <p>
                  Elevate your brand with our top-notch brochure design
                  services. As a leading provider in the industry, we specialize
                  in creating visually stunning and informative brochures for
                  businesses of all sizes. Our dedicated team of designers
                  collaborates to capture the essence of your brand and
                  communicate it effectively to your audience.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <iframe
                width="100%"
                src="assets/image/adret-video.mp4"
                title="Adret intro video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                data-aos="zoom-out"
                data-aos-duration="2000"
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      <section className="logo_design_project p-0">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/logo-design-img1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="heading" data-aos="fade-left" data-aos-duration="2000">
              <h2>What can we do for you?</h2>
              <p>We would be happy to discuss the project with you in person</p>
              <h4>
                Call To Us : <Link to="tel:+18885054030">+1 888 505 4030</Link>
              </h4>
              <div className="btn_sec">
                <a href="" className="btn2">
                  Drop in a message
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_sec">
        <div className="container">
          <div className="heading" data-aos="fade-up" data-aos-duration="2000">
            <h2>
              Why Choose Adret Software for <br />
              Professional Brochure Design Services
            </h2>
            <p>
              A captivating brochure is essential for effective communication.
              At Adret Software, a leading brochure design service provider, we
              specialize in crafting impactful brochures that tell your brand
              story. Our experienced team of designers combines creativity with
              strategic insight to deliver brochure designs that stand out.
            </p>
          </div>
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Strategic Design Approach</h5>
                      <p>
                        Our brochure design process begins with a strategic
                        analysis of your content and target audience to ensure
                        the most effective design solutions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Creative Customization</h5>
                      <p>
                        At Adret Software, we tailor our brochure designs to
                        align with your brand's unique identity, ensuring a
                        customized and visually appealing result.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Thorough Content Integration</h5>
                      <p>
                        We integrate your content seamlessly into the brochure
                        design, ensuring a cohesive and engaging narrative for
                        your audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Collaborative Design Process</h5>
                      <p>
                        Collaboration is at the core of our design process. We
                        involve our clients at every stage to ensure the final
                        brochure meets their expectations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_sec" data-aos="fade-up" data-aos-duration="3000">
                {/* <a href="" className="btn1">
                  Corporate Identity Services
                </a> */}
              </div>
            </div>
            <div className="col-lg-4">
              <img
                src="assets/image/brochure-design1.jpg"
                className="img-fluid  rounded"
                alt=""
                data-aos="zoom-out"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="portfolio_logo bg_color1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center row_gap">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Explore Our Brochure Design Showcase</h2>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 row-below text-center">
              <div className="btn_sec" data-aos="zoom-in" data-aos-duration="2000">
                <a href="" className="btn1">
                  See Full Portfolio
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <OurClient />

      <Faq data={data} />
    </>
  );
}
