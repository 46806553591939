import React from "react";
import OurClient from "../../OurClient/OurClient";
import Faq from "../../Faq/Faq";
import { Link } from "react-router-dom";

const data = {
  faqHeader: "Logo Design Services – Frequently Asked Questions",
  caption:
    "Before engaging our logo design services, clear your doubts. For more assistance, feel free to ask our experts.",
  faqContent: [
    {
      ques: "Why is a Professional Logo Important for Your Business?",
      ans: "A professional logo is essential for your business as it serves as the visual representation of your brand. It creates a memorable and recognizable identity that communicates your brand values, personality, and uniqueness to your target audience.",
    },
    {
      ques: "What Makes a Good Logo Design?",
      ans: "A good logo design is simple, memorable, versatile, timeless, and appropriate for your business. It should effectively convey your brand's message and evoke the desired emotions in your audience. Additionally, a good logo should be scalable and work well across various platforms and mediums.",
    },
    {
      ques: "How Does Logo Design Impact Brand Perception?",
      ans: "Logo design plays a significant role in shaping brand perception. A well-designed logo can enhance brand credibility, professionalism, and trustworthiness in the eyes of consumers. It helps differentiate your business from competitors and leaves a positive impression on your target audience.",
    },
    {
      ques: "Can You Customize a Logo Design According to My Business Needs?",
      ans: "Absolutely! Our logo design services are tailored to meet your specific business needs and preferences. Whether you're looking for a completely unique logo or want to refine your existing logo, our team of designers will work closely with you to create a customized solution that aligns with your brand identity and objectives.",
    },
  ],
};

const clients = [];

for (let i = 1; i <= 40; i++) {
  clients.push({
    id: i,
    src: `assets/image/clientsLogo/client-${i}.png`,
  });
}

export default function BrandIdentity() {
  return (
    <>
      <section className="logo_design_sec1 bg_color1">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-8 col-sm-9">
              <div
                className="heading"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <h1>Brand Identity</h1>
                <p>
                  At Adret Software , we understand the power of a compelling
                  brand identity. In today's competitive landscape, your brand
                  is more than just a logo; it's the essence of your company's
                  personality. We specialize in the art and science of creating
                  unique brand identities that captivate audiences and leave a
                  lasting impression.
                </p>
                <div className="btn_sec ">
                  <a href="/contact" className="btn1 btn_gap">
                    Quick Enquiry
                  </a>
                  <a href="/portfolio" className="btn3">
                    Portfolio
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-3">
              <img
                src="assets/image/brand-identity-img.jpg"
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="2000"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_business">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div
                className="heading"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Our Mission</h2>
                <p>
                  At Adret Software, our mission is to be the architects of
                  distinction, guiding businesses to forge identities that
                  transcend the ordinary. We are committed to breathing life
                  into brands, understanding that they are not merely symbols
                  but living entities that connect with people. Our mission is
                  rooted in the belief that every brand deserves to be unique,
                  authentic, and unforgettable.
                </p>
                <p>
                  We strive to empower businesses, large and small, with the
                  tools to articulate their essence and values through
                  compelling visual and verbal expressions. By combining
                  creativity with strategic insight, we aim to shape brand
                  identities that resonate in the hearts and minds of audiences,
                  fostering trust and loyalty.
                </p>
                <div className="btn_sec">
                  <Link to="/contact" className="btn1 btn_gap">
                    Request a Quote
                  </Link>
                  <Link to="/pricing" className="btn3">
                    Our Packages
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="logo_design_best">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Brand Identity Services - Crafted for Excellence</h2>
                <p>
                  Elevate your brand with our top-notch brand identity services.
                  As a leading provider in the industry, we specialize in
                  creating cohesive and impactful brand identities for
                  businesses of all sizes, from startups to established
                  enterprises. Our dedicated team of brand strategists and
                  designers works collaboratively to capture the essence of your
                  brand and communicate it effectively to your target audience.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <iframe
                width="100%"
                src="assets/image/adret-video.mp4"
                title="Adret intro video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                data-aos="zoom-out"
                data-aos-duration="2000"
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      <section className="logo_design_project p-0">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/logo-design-img1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div
              className="heading"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h2>What can we do for you?</h2>
              <p>We would be happy to discuss the project with you in person</p>
              <h4>
                Call To Us : <Link to="tel:+18885054030">+1 888 505 4030</Link>
              </h4>
              <div className="btn_sec">
                <a href="/contact" className="btn2">
                  Drop in a message
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_sec">
        <div className="container">
          <div className="heading" data-aos="fade-up" data-aos-duration="2000">
            <h2>
              Why Choose Adret Software for <br />
              Professional Brand Identity Services
            </h2>
            <p>
              A compelling brand identity is the cornerstone of successful
              marketing. At Adret Software, a leading brand identity service
              provider, we create impactful brand identities that resonate with
              your audience. Our experienced team of brand strategists and
              designers blends creativity with strategic insight to craft a
              brand identity that sets you apart.
            </p>
          </div>
          <div className="row g-4">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Strategic Brand Analysis</h5>
                      <p>
                        Our brand identity process begins with a thorough
                        analysis of your brand's essence and market positioning.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Creative Design Solutions</h5>
                      <p>
                        At Adret Software, we go beyond aesthetics. Our design
                        solutions are crafted with creativity at the core
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Strategic Content Development</h5>
                      <p>
                        Content is king, and at Adret Software, we understand
                        its strategic importance.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Data-Driven Marketing Insights</h5>
                      <p>
                        Harness the power of data to fuel your marketing
                        strategies.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <img
                src="assets/image/brand-id.png"
                className="img-fluid rounded"
                alt=""
                data-aos="zoom-out"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="portfolio_logo bg_color1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center row_gap">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Explore Our Brand Identity Showcase</h2>
              </div>
            </div>
          </div>
          <section className="our_clientele_sec pt-0">
            <div className="container">
              <div className="row row-cols-lg-6 g-2 row-cols-md-5 row-cols-sm-4 row-cols-3 justify-content-center align-items-center ">
                {clients.map((e) => {
                  return (
                    <div className="col-lg">
                      <div
                        className="client_box aos-init "
                        data-aos="zoom-in"
                        data-aos-duration={2000}
                      >
                        <img
                          src={e.src}
                          className="img-fluid client-image-fit"
                          alt=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-lg-12 row-below text-center">
              <div className="btn_sec" data-aos="zoom-in" data-aos-duration="2000">
                <a href="" className="btn1">
                  See Full Portfolio
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <OurClient />
      <Faq data={data} />
    </>
  );
}
