import React from "react";
import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./../assets/js/error404.json";
import { Link, useNavigate } from "react-router-dom";

const Error404 = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
  };

  const { View } = useLottie(options);
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row align-items-center justify-content">
        <div className="col-lg-6">{View}</div>
        <div className="col-lg-6">
          <h3>Looks like you are lost!</h3>
          <p className="m-0">Sorry, the page you're looking for does not exist here.</p>
          <p className="m-0 mb-4"> Please check the link again or try to go back.</p>

          <Link to="/" className="btn1 btn_gap">
            Go Home
          </Link>
          <button onClick={() => navigate(-1)} className="btn1">
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Error404;
