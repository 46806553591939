import React,{useState} from "react";
import OurClient from "../OurClient/OurClient";

export default function SmoPrice() {
  const [price, setPrice] = useState({ silver: 249, gold: 349});
  const [symbol, setSymbol] = useState(true);
    const handleToggle = () => {    
        let rate = 0.93;
        setSymbol(!symbol);
        if (symbol === true) {
          setPrice({
            silver: Math.round(249* rate),
            gold: Math.round(349 * rate),
          });
        } else {
          setPrice({
            silver: 249,
            gold: 349
          });
        }
      };
  return (
    <>
      <div className="inner-page" id="contact-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>SMO</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <i className="ri-home-4-line"></i> Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      SMO
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="seo_content_sec">
        <div className="container">
        <div className="row">
                    <div className="col-g-12 text-right">
                        <div className="switch_butt">
                            <div className="form-check form-switch">
                                <label>$</label>
                                <input onClick={handleToggle} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                <label>€</label>
                              </div>
                        </div>
                       
                    </div>
                </div>
          <div className="row g-5 justify-content-center mt-3">
            <div className="col-lg-6">
              <div
                className="pricing_card"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="top_header">
                  <h4>Silver</h4>
                  <div className="border_btn"></div>
                  <div className="pirze_sec">
                  {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                  </div>
                  <div className="con_month">
                    <p className="mb-0">Per Month</p>
                  </div>

                  <div className="down_arow">
                    <div className="down_arow_cont"></div>
                    <i className="fa fa-arrow-down"></i>
                  </div>
                  {/* <div className="over_heading">
                    <span>Silver</span>
                  </div> */}
                </div>
                <div className="pricing_body">
                  <ul className="pricing_list">
                    <li>
                      <strong> FACEBOOK MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>3 Months Timeline
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Facebook Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Fan Page Creation
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Posting And Updates
                    </li>
                    <li>
                      <strong>TWITTER MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Twitter Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Twitter Page Creation
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Posting And Updates
                    </li>
                    <li>
                      <strong> YOUTUBE MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>YouTube Channel Creation
                      (If Required)
                    </li>
                    <li>
                      <strong> IMAGE OPTIMIZATION</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Pinterest Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Create Boards
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Uploading Pins on Boards
                    </li>
                    <li>
                      <strong>OFF PAGE AND OTHER MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Content Posting
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Bookmarking
                    </li>

                    <li>
                      <strong> CLIENT COMMUNICATION AND REPORT</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Monthly Report
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Activities
                      Monitoring
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Profile Improvement
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Dedicated Account Manager
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Customer Support (Email,
                      Chat & Telephone)
                    </li>
                  </ul>
                  <a href="" className="btn_buy">
                    BUY NOW
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="pricing_card"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="top_header">
                  <h4>Gold</h4>
                  <div className="border_btn"></div>
                  <div className="pirze_sec">
                  {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                  </div>
                  <div className="con_month">
                    <p className="mb-0">Per Month</p>
                  </div>

                  <div className="down_arow">
                    <div className="down_arow_cont"></div>
                    <i className="fa fa-arrow-down"></i>
                  </div>
                  {/* <div className="over_heading">
                    <span>Gold</span>
                  </div> */}
                </div>
                <div className="pricing_body">
                  <ul className="pricing_list">
                    <li>
                      <strong> FACEBOOK MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>3 Months Timeline
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Facebook Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Fan Page Creation
                    </li>
                    <li>
                      <i className="fa fa-check"></i>FB Cover Photo Creation
                    </li>
                    <li>
                      <i className="fa fa-check"></i>FB Profile Design
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Posting And Updates
                    </li>

                    <li>
                      <strong>TWITTER MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Twitter Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Twitter Page Creation
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Twitter Profile Design
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Posting And Updates
                    </li>

                    <li>
                      <strong> YOUTUBE MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>YouTube Channel Creation
                      (If Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>YouTube Background Design
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Video Optimization
                    </li>
                    <li>
                      <strong> IMAGE OPTIMIZATION</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Pinterest Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Instagram Account Setup (If
                      Required)
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Create Boards
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Uploading Pins on Boards
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Instagram Regular Posting
                    </li>

                    <li>
                      <strong> OFF PAGE AND OTHER MARKETING</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Content Posting
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Blog Account Setup
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Blog Posting
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Blog Post Social
                      Bookmarking
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Bookmarking
                    </li>
                    <li>
                      <strong> CLIENT COMMUNICATION AND REPORT</strong>
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Monthly Report
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Activities
                      Monitoring
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Profile Improvement
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Dedicated Account Manager
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Customer Support (Email,
                      Chat & Telephone)
                    </li>
                  </ul>
                  <a href="" className="btn_buy">
                    BUY NOW
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="logo_design_project p-0" id="android_app">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/call-suprot-img.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 text-center">
            <div
              className="heading aos-init aos-animate"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h3> Support For 24/7</h3>
              <p>
                What can we do for you? <br />
                We would be happy to discuss the project with you in person
              </p>
              <div className="btn_sec">
                <a href="" className="btn2">
                  Request a quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurClient />
    </>
  );
}
