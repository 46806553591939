import React from "react";

export default function Header() {
  return (
    <>
      <div className="top_ber">
        <div className="container container-flex">
          <div class="dropdown">
            <button
              class="btn btn-white dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="/assets/image/phone.gif"
                alt=""
                className="moving-phicon"
              />
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li class="dropdown-item">
                <a href="tel:+13157158485">
                  <img src="assets/image/usa.png" alt="" /> +1 315 715 8485
                </a>
              </li>
              <li class="dropdown-item">
                <a href="tel:+441223968001">
                  <img src="assets/image/uk.png" alt="" /> +44 12 2396 8001
                </a>
              </li>
              <li class="dropdown-item">
                <a href="tel:+31852087966">
                  <img src="assets/image/netherland.png" alt="" /> +31 85 208
                  7966
                </a>
              </li>
              <li class="dropdown-item">
                <a href="tel:+3225862296">
                  {" "}
                  <img src="assets/image/belgium.webp" alt="" /> +32 2 586 22 96
                </a>
              </li>
              <li class="dropdown-item">
                <a href="tel:+919432205976">
                  <img src="assets/image/india.png" alt="" /> +91 94322 05976
                </a>
              </li>
            </ul>
          </div>
          <div className="tober_right">
            <a href="mailto:info@adretsoftware.com">
              <i className="fa fa-envelope"></i> info@adretsoftware.com
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
