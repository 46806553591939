import React from "react";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import Faq from "../Faq/Faq";
const data = {
  faqHeader: "Frequently Asked Questions - Hire PHP Developer",
  caption:
    "Explore the details of hiring PHP developers from our skilled team. Should you have additional inquiries or wish to discuss your project specifics, feel free to reach out to us.",
  faqContent: [
    {
      ques: "What skills should I look for when hiring a PHP developer?",
      ans: "When hiring a PHP developer, it's important to look for expertise in PHP programming language, knowledge of one or more PHP frameworks like Laravel, Symfony, or CodeIgniter, familiarity with front-end technologies such as HTML, CSS, and JavaScript, understanding of databases and SQL, experience with version control systems like Git, and proficiency in problem-solving and debugging.",
    },
    {
      ques: "Do you provide flexible engagement models for PHP developers?",
      ans: "Yes, we offer flexible engagement models tailored to your project requirements. Whether you need a dedicated PHP developer for a long-term project or prefer hourly-based arrangements for short-term tasks, we can accommodate your needs.",
    },
    {
      ques: "Can your PHP developers work remotely?",
      ans: "Absolutely! Our PHP developers are experienced in remote collaboration and can effectively work with your team regardless of geographical location. We utilize communication tools and project management systems to ensure seamless coordination and timely project delivery.",
    },
    {
      ques: "How do you ensure the quality of PHP development?",
      ans: "Quality assurance is a priority in our PHP development process. Our developers follow coding standards, conduct thorough testing using PHPUnit and other testing frameworks, adhere to best practices for security and performance optimization, and strive to deliver high-quality, scalable web applications that meet your business requirements.",
    },
  ],
};

export default function HirePhpDev() {
  return (
    <>
      <div
        className="inner-page"
        id="hire-us-bg"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Hire Php Developer</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line"></i>Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hire php Developer
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading row_gaping">
            <h2 className="section-title-2 text-center">
              Engage php Experts at the Best rates
            </h2>
          </div>
          <div className="center-row row row-below g-2 row-cols-lg-6 row-cols-sm-3 row-cols-2">
            <div className="col-md text-center">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-1.png" alt="" />
                <h5>One Week Free Trial</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-2.png" alt="" />
                <h5>Minimum 3+ Years of Experience</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-3.png" alt="" />
                <h5>Non-Disclosure Agreement</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-4.png" alt="" />

                <h5>Flexible Hiring Models</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-5.png" alt="" />

                <h5>Competitive Rate</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-6.png" alt="" />
                <h5>Source Code Delivery</h5>
              </div>
            </div>
          </div>
          <div className="btn_sec text-center row-below">
            <Link to="/contact" className="btn2 btn_gap">
              Get Started
            </Link>
            <Link to="/clients" className="btn2">
              Our Clients
            </Link>
          </div>
        </div>
      </section>

      <section
        className="section hire_content_anguler"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="small-hire ">
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <div className="small_hire_content heading">
                <h3>WHAT CAN WE DO FOR YOU?</h3>
                <p>
                  We would be happy to discuss the project with you in person
                </p>
                <h4>
                  Call us on <Link to="tel:+18885054030">+1 888 505 4030</Link>
                </h4>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    DROP IN A MESSAGE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="section-title">
              Hire Offshore php Developers to Meet <br /> Custom Requirements
            </h2>
            <p className="section-subtitle pt-3">
              Seize the opportunity to hire skilled offshore PHP developers for
              your projects. Our developers bring diverse expertise to the
              table, delivering high-quality PHP solutions tailored to your
              business needs. With flexible engagement models, we cater to the
              complexity and duration of your projects. Enjoy the benefits of
              cost-effective offshore development while maintaining quality and
              confidentiality.{" "}
            </p>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-7.png" alt="" />
                <h6 className="details-heading">Custom php Development</h6>
                <p>
                  Elevate your digital presence with our custom PHP development
                  services. Our expert PHP developers craft bespoke solutions,
                  aligning seamlessly with your unique business requirements.
                  Whether you need a dynamic website, a robust web application,
                  or intricate backend development, we tailor our PHP expertise
                  to meet your goals.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-8.png" alt="" />
                <h6 className="details-heading">API Integration</h6>
                <p className="">
                  Enhance your user experience with our expert PHP API
                  integration services. We specialize in seamlessly connecting
                  your PHP applications to various APIs, ensuring a smooth and
                  interactive experience for your users. Whether it's
                  integrating social media logins, payment gateways, or
                  third-party services, our skilled PHP developers ensure secure
                  and efficient data exchange.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-9.png" alt="" />
                <h6 className="details-heading">Ajax Integration</h6>
                <p className="">
                  Elevate your PHP web applications with our Ajax integration
                  services, ensuring dynamic and responsive user experiences.
                  Our skilled PHP developers seamlessly implement Ajax, enabling
                  real-time updates and interactive features without full-page
                  reloads. Whether it's dynamic content loading, form
                  submissions, or live updates, we optimize your PHP
                  applications for enhanced interactivity.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-10.png" alt="" />
                <h6 className="details-heading">Migration</h6>
                <p className="">
                  Upgrade your PHP applications effortlessly with our migration
                  services. Whether transitioning between PHP versions or
                  migrating from other frameworks, our expert PHP developers
                  ensure a smooth and efficient process
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white  req-card ">
                <img src="assets/image/hire-angular-icon-11.png" alt="" />
                <h6 className="details-heading">Support & Maintenance</h6>
                <p className="py-2">
                  Ensure the continuous optimal performance of your PHP web
                  applications with our dedicated support and maintenance
                  services. Our expert PHP developers are committed to promptly
                  resolving issues, applying updates, and proactively monitoring
                  for potential challenges.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-12.png" alt="" />
                <h6 className="details-heading">Ecommerce Development</h6>
                <p className="py-2">
                  Revolutionize your online business with our expert PHP
                  Ecommerce development services. Our skilled PHP developers
                  specialize in crafting robust, scalable, and feature-rich
                  Ecommerce solutions tailored to your unique business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading  text-center">
            <h2>Why Choose Us</h2>
          </div>
          <div className="row g-3 ">
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-13.png" alt="" />
                </div>
                <h5 className="my-3">Industry Experts</h5>
                <p>
                  Our in-house team is enriched with trusted and experienced
                  developers - no freelancer.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-14.png" alt="" />
                </div>
                <h5 className="my-3">Widely recommended</h5>
                <p>
                  We got hundreds of real 5-star ratings and reviews on Google,
                  GoodFirms, and Clutch combined.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-15.png" alt="" />
                </div>
                <h5 className="my-3">Customer Service</h5>
                <p>
                  Our zero complaint work approach helps us to ensure a seamless
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-16.png" alt="" />
                </div>
                <h5 className="my-3">Ideal Workplace</h5>
                <p>
                  We cultivate a productive work environment with a trendy setup
                  to enhance the outcomes.
                </p>
              </div>
            </div>
            <div className="row-below btn_sec text-center">
              <Link to="/contact" className="btn2 btn_gap">
                Get Started
              </Link>
              <Link to="/clients" className="btn2">
                Our Clients
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Slider />

      <Faq data={data} />
    </>
  );
}
