import React from "react";

const Privacy = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading">
                <h2>PRIVACY POLICY</h2>
                <p>
                  This security strategy is essentially set according to how
                  Adret Software Services Pvt. Ltd. utilizes any information
                  that the customer gives when utilizing this site
                  (info@adretsoftware.com). We are devoted to ensuring your
                  protection and watching to its fullest. At the point when we
                  request that you give certain data by which you can be
                  distinguished while using this specic site, so you can be
                  guaranteed that it might be used as per this security
                  articulation. We change this strategy now and again, so it is
                  suggested you check this page frequently to ensure that you
                  are content with any progressions that we make.
                </p>
                <p>
                  Mentioned below is a portion of the important data which we
                  anticipate from you:
                  <ul>
                    <li>
                      {" "}
                      1. Client (business/singular) name and occupation title
                    </li>
                    <li>
                      {" "}
                      2. Contact numbers and data like postal location,
                      telephone no. and email address
                    </li>
                    <li>
                      {" "}
                      3. Segment data like postcode, inclinations, and interests{" "}
                    </li>
                    <li>
                      {" "}
                      4. Other data identied with client studies and oers
                    </li>
                  </ul>
                </p>
                <h3>WHY DO WE REQUIRE THIS INFORMATION?</h3>
                <p>
                  Adret Software Services Pvt. Ltd. need this data to know you
                  in a superior manner and cook you rst-className benets, specically
                  for the accompanying purposes:
                  <ul>
                    <li>
                      1. Above all, for reaching you by telephone, email, fax,
                      or mail.{" "}
                    </li>
                    <li>
                      2. Furthermore make use of the data to improve items and
                      administrations
                    </li>
                    <li>3. Thirdly, for inner record keeping </li>
                    <li>
                      4. What's more, nally, for sending limited time messages
                      about recently dispatched items, unique orders, or for
                      some other fundamental reasons
                    </li>
                  </ul>
                </p>
                <h3>SECURITY</h3>
                <p>
                  About security, to maintain a strategic distance from
                  unapproved access or exposure, Adret Software Services has set
                  up appropriate physical, electronic, and administrative
                  systems to ensure and get the data gathered on the web. Adret
                  Software Services vows not to sell, or rent any of your data
                  to outsiders except if and until Adret Software Services has
                  your consent or is required by law to do as such. On the o
                  chance if you track down that any data Adret Software Services
                  is hanging on you is o base or fragmented, if it's not too
                  much trouble, email us or think of us at the earliest
                  opportunity. Adret Software Services will doubtlessly
                  investigate the matter and will address it straightaway.
                </p>
                <h3>CONTROLLING YOUR PERSONAL INFORMATION:</h3>
                <p>
                  You may like to limit the utilization of your data in the
                  following manners:
                  <p>
                    <ul>
                      <li>
                        {" "}
                        1. At whatever point you are approached to ll in a
                        structure on the site, search for the container that you
                        can snap to demonstrate that you do not need the data to
                        be held by anyone for direct promoting purposes;
                      </li>
                      <li>
                        {" "}
                        2. On the off chance that you have recently consented to
                        us utilizing your data for direct promoting purposes,
                        you may change your decision whenever by writing to or
                        messaging us at (info@adretsoftware.com)
                      </li>
                    </ul>
                  </p>
                </p>
                <h3>
                  YOU CAN REACH OUT TO US THROUGH BELOW MENTIONED ADDRESS:
                </h3>
                Unit No.205, Merlin Matrix, DN 10, Sector V, Bidhannagar,
                Kolkata, West Bengal 700091
                <br />
                <a href="tel:+91 9294386499">Contact: +91 9294386499</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
