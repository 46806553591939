import React from "react";
import OurClient from "../../OurClient/OurClient";
import Faq from "../../Faq/Faq";
import { Link } from "react-router-dom";

const data = {
  faqHeader: "Frequently Asked Questions - Content Writing Services",
  caption:
    "Explore the details of our content writing services. If you have additional questions, feel free to reach out to our knowledgeable team.",
  faqContent: [
    {
      ques: " What Elements Constitute Effective Content Writing?",
      ans: "Effective content writing incorporates clear and concise language, a deep understanding of the target audience, a compelling tone, and a strategic use of keywords. It aims to engage readers and deliver the intended message in a way that resonates with the audience.",
    },
    {
      ques: "Why is Content Writing Important for Marketing?",
      ans: "Content writing plays a crucial role in marketing by providing valuable and relevant information to your audience. It helps establish your brand as an authority in your industry, improves search engine visibility, and builds a connection with your target audience through informative and engaging content.",
    },
    {
      ques: "Do You Provide Custom Content Writing Solutions?",
      ans: "Absolutely! Our content writing services are tailored to meet your unique requirements. We work collaboratively to understand your brand, objectives, and target audience, creating custom content that aligns with your brand identity and messaging.",
    },
    {
      ques: "  How Can Content Writing Enhance Brand Visibility?",
      ans: "Content writing contributes to brand visibility by creating compelling and shareable content. A well-crafted piece of content reinforces your brand identity, making it more recognizable and leaving a lasting impression on your target audience. It also encourages audience engagement and sharing, further expanding your brand's reach.",
    },
  ],
};

export default function ContentWriting() {
  return (
    <>
      <section className="logo_design_sec1 bg_color1">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-8 col-sm-9">
              <div
                className="heading"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <h1>Content Writing</h1>
                <p>
                  At Adret Software, we understand the power of words. Content
                  writing is more than just stringing sentences together; it's
                  about conveying your message with clarity and impact. We
                  specialize in the art and science of creating compelling
                  content that resonates with your audience, drives engagement,
                  and communicates your brand's story effectively.
                </p>
                <div className="btn_sec ">
                  <Link to="/contact" className="btn1 btn_gap">
                    Quick Enquiry
                  </Link>
                  <Link to="/blogs" className="btn3">
                    Read our Articles
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-3">
              <img
                src="assets/image/content-img.jpeg"
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="2000"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_business">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Our Approach to Content Writing</h2>
                <p>
                  At Adret Software, our approach to content writing goes beyond
                  mere words. We see it as a strategic tool that shapes your
                  brand's narrative, engages your audience, and establishes your
                  authority in the industry. Our mission is to transform your
                  ideas into impactful content that connects with your target
                  audience on a deeper level.
                </p>
                <p>
                  Whether you need website content, blog posts, marketing copy,
                  or social media content, our content writing services are
                  tailored to deliver compelling, well-researched, and
                  audience-centric content that aligns with your brand's goals.
                </p>
                <div className="btn_sec">
                  <Link to="/contact" className="btn1 btn_gap">
                    Request a Quote
                  </Link>
                  <Link to="/portfolio" className="btn3">
                    Content Packages
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="logo_design_best">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Examples of Our Content Writing</h2>
                <p>
                  Immerse yourself in the essence of our written creations. As a
                  trailblazer in the industry, we take pride in curating content
                  that not only imparts knowledge but also sparks curiosity and
                  fosters meaningful connections with your audience.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <iframe
                width="100%"
                src="assets/image/portfolio/explainer-video-1.mp4"
                title="Adret intro video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                data-aos="zoom-out"
                data-aos-duration="2000"
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      <section className="logo_design_project p-0">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/logo-design-img1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="heading" data-aos="fade-left" data-aos-duration="2000">
              <h2>Ready to Elevate Your Content?</h2>
              <p>We would love to discuss your content goals and strategies.</p>
              <h4>
                Call To Us : <Link to="tel:+18885054030">+1 888 505 4030</Link>
              </h4>
              <div className="btn_sec">
                <Link to="/contact" className="btn2">
                  Drop in a message
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_sec">
        <div className="container">
          <div className="heading" data-aos="fade-up" data-aos-duration="2000">
            <h2>Why Choose Adret Software for Professional Content Writing</h2>
            <p>
              Quality content is the heartbeat of effective communication. At
              Adret Software, a leading content writing service provider, we
              craft words that resonate, inform, and inspire. Our team of
              experienced writers combines creativity with industry knowledge to
              deliver content that not only meets but exceeds your expectations.
            </p>
          </div>
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Strategic Content Development</h5>
                      <p>
                        Our content development process is strategically
                        designed to align with your brand, industry, and target
                        audience, ensuring relevance and impact.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Engaging Copywriting</h5>
                      <p>
                        We go beyond words; our copywriting is crafted to engage
                        your audience, drive action, and convey your brand
                        message persuasively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>SEO Optimization</h5>
                      <p>
                        Elevate your online presence with our SEO-optimized
                        content. We meticulously research and incorporate
                        keywords to ensure your content ranks high on search
                        engines, attracting a wider audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Social Media Engagement</h5>
                      <p>
                        Extend your brand's reach with content designed for
                        social media platforms. Our team understands the nuances
                        of each platform, creating shareable and engaging
                        content that sparks conversations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="btn_sec" data-aos="fade-up" data-aos-duration="3000">
                <a href="" className="btn1">
                  Explore Explainer Video
                </a>
              </div> */}
            </div>
            <div className="col-lg-4">
              <img
                src="assets/image/content-img1.jpeg"
                className="img-fluid  rounded"
                alt=""
                data-aos="zoom-out"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="portfolio_logo bg_color1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center row_gap">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Explore Our Brand Identity Showcase</h2>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 row-below text-center">
              <div className="btn_sec" data-aos="zoom-in" data-aos-duration="2000">
                <a href="" className="btn1">
                  See Full Portfolio
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <OurClient />
      <Faq data={data} />
    </>
  );
}
