import React from "react";
import { Link } from "react-router-dom";

function TermsCondition() {
  return (
    <div className="container py-5">
      <h1><u>Terms and Conditions</u></h1>
      <p>
        Welcome to Adret Software and Services Pvt Ltd. These terms and
        conditions outline the rules and regulations for the use of Adret
        Software and Services Pvt Ltd's Website, located at 
        <Link to="/"> www.adretsoftware.com</Link>.
      </p>

      <h2>1. Introduction</h2>
      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Adret Software and Services Pvt Ltd
        if you do not agree to take all of the terms and conditions stated on
        this page.
      </p>

      <h2>2. Intellectual Property Rights</h2>
      <p>
        Other than the content you own, under these terms, Adret Software and
        Services Pvt Ltd and/or its licensors own all the intellectual property
        rights and materials contained in this Website.
      </p>

      <h2>3. Restrictions</h2>
      <p>
        You are specifically restricted from all of the following:
        <ul>
          <li>publishing any Website material in any other media;</li>
          <li>
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li>publicly performing and/or showing any Website material;</li>
          <li>
            using this Website in any way that is or may be damaging to this
            Website;
          </li>
          <li>
            using this Website in any way that impacts user access to this
            Website;
          </li>
        </ul>
      </p>

      <h2>4. Limitation of liability</h2>
      <p>
        In no event shall Adret Software and Services Pvt Ltd, nor any of its
        officers, directors, and employees, be held liable for anything arising
        out of or in any way connected with your use of this Website.
      </p>

      <h2>5. Severability</h2>
      <p>
        If any provision of these terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting the
        remaining provisions herein.
      </p>

      <h2>6. Variation of Terms</h2>
      <p>
        Adret Software and Services Pvt Ltd is permitted to revise these terms
        at any time as it sees fit, and by using this Website you are expected
        to review these terms on a regular basis.
      </p>

      <h2>7. Changes to Terms and Conditions</h2>
      <p>
        We reserve the right to modify or replace these Terms and Conditions at
        any time without prior notice. By continuing to access or use our
        website after any revisions become effective, you agree to be bound by
        the revised terms. If you do not agree to the new terms, you must stop
        using our website.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at{" "}
        <u><a href="mailto:info@adretsoftware.com">info@adretsoftware.com</a>.</u>
      </p>

      <p>
        Thank you for visiting Adret Software Service PVT LTD! We hope you enjoy
        your experience on our website.
      </p>
    </div>
  );
}

export default TermsCondition;