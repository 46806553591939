import React from "react";
import { Link } from "react-router-dom";

const FoodDeliveryApp = () => {
  return (
    <>
      <div
        className="inner-page"
        id="nodejs-design-bg"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h1>Food Delivery App</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="ri-home-4-line" />
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Food Delivery App
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section" data-aos="fade-up" data-aos-duration={1500}>
        <div className="bg-body-tertiary">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h2 className="section-title">
                  Explore the Features of Our Food Delivery App{" "}
                </h2>
                <div className="d-flex align-items-center">
                  <i className="ri-pencil-ruler-line icon-color mr-3" />
                  <h5>Quick and Easy Ordering</h5>
                </div>
                <p className="">
                  Our Food Delivery App provides a seamless and user-friendly
                  ordering process. Customers can quickly browse through menus,
                  choose their favorite dishes, and place orders with just a few
                  clicks.
                </p>
                <div className="text-center text-md-left ">
                  <Link to="/portfolio" className="btn1 mb-2 mb-lg-0">
                  Explore Now
                  </Link>
                  {/* <button className="btn1">Explore</button> */}
                </div>
              </div>
              <div className="col-md-5">
                <div className="">
                  <img
                    src="assets/image/solutions/food-delivery-app.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/food-tracking.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3 ">
                  <img
                    width={64}
                    height={64}
                    src="https://img.icons8.com/ios/64/ffffff/map.png"
                    className="mr-3"
                    alt="map"
                  />
                  Real-time Order Tracking
                </h5>
                <p>
                  The state-of-the-art Learning Management System provides you
                  with the facility of uploading course materials in the form of
                  engaging videos and PDFs pertaining to any subject or training
                  module. The course materials can be easily managed at your end
                  through additions, deletions, or modifications. A student or
                  trainee can visit the relevant section(s) of the eLearning
                  solutions portal and access course materials for which he or
                  she has enrolled.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-className-schedule.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-calendar icon-color mr-3" />
                  <h5 className=""> Secure Online Payments</h5>
                </div>
                <p>
                  Our Food Delivery App ensures secure online payments.
                  Customers can choose from a variety of payment options,
                  including credit cards, digital wallets, and more. A secure
                  and hassle-free payment experience is our priority.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/food-rating.png"
                  className="img-fluid mx-auto"
                  alt=""
                  style={{ width: "150px", objectFit: "contain" }}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/ios/50/ffffff/pass.png"
                    className="mr-3"
                    alt="pass"
                  />
                  Customer Reviews and Ratings
                </h5>
                <p>
                  Encourage customer feedback and reviews to enhance your
                  services. Our app allows customers to rate their orders and
                  leave valuable reviews. Improve your business based on
                  customer feedback and create a trustworthy relationship.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-specialists-guidance.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/sf-ultralight-filled/50/0d6efd/chat.png"
                    className="mr-3"
                    alt="chat"
                  />
                  Expert Guidance for Food Enthusiasts
                </h5>
                <p>
                  Our innovative Food Delivery App services provide users with
                  access to expert guidance. Whether you have queries about a
                  particular dish, dietary information, or suggestions, our
                  experts are ready to assist. Users can easily submit their
                  questions, and our culinary specialists respond promptly with
                  valuable insights and recommendations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  bg_color"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/food-delivery-customization.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-cogs icon-color-white mr-3" />
                  <h5 className="">Personalized Customization</h5>
                </div>
                <p>
                  Every food lover has unique preferences. Our Food Delivery App
                  allows for a personalized experience. Tailor the app according
                  to your taste preferences, dietary restrictions, and favorite
                  cuisines. Customize your food journey with ease, creating a
                  delightful and unique dining experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="https://www.webguru-india.com/assets/images/learning-management-system-userfriendly.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={66}
                    height={66}
                    src="https://img.icons8.com/external-3d-design-circle/66/external-Admin-Panel-design-and-development-3d-design-circle.png"
                    alt="external-Admin-Panel-design-and-development-3d-design-circle"
                  />{" "}
                  User-Friendly Ordering
                </h5>
                <p>
                  Our Food Delivery App ensures a seamless and user-friendly
                  ordering process. With an easy-to-use interface, customers can
                  navigate through various cuisines, explore menus, and place
                  orders effortlessly. The intuitive design enhances the overall
                  experience, making it convenient for users to enjoy their
                  favorite meals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color  after-image overflow-hidden"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="mobile-friendly-image solutions-image">
                <img
                  src="assets/image/solutions/food-img.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    className="mr-3"
                    src="https://img.icons8.com/ios/50/ffffff/media-queries.png"
                    alt="media-queries"
                  />
                  Mobile Friendly
                </h5>
                <p>
                  In a world where mobile devices play a crucial role, our Food
                  Delivery App ensures a seamless mobile experience. Users can
                  easily access features, place orders, and track deliveries on
                  the go. Stay connected with your favorite restaurants and
                  cuisines anytime, anywhere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/food-dashboard.png"
                  className="img-fluid w-100"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={60}
                    height={60}
                    className="mr-3"
                    src="https://img.icons8.com/color/60/combo-chart--v1.png"
                    alt="combo-chart--v1"
                  />
                  Order Insights & Reporting
                </h5>
                <p>
                  Gain valuable insights into your food delivery business with
                  our robust reporting feature. Track orders, customer
                  preferences, and delivery performance. Our app generates
                  comprehensive reports, allowing you to analyze key metrics and
                  make informed business decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section  mb-10 py-3 py-lg-4"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <h2 className="section-title-2 mb-10">
            Achieve Seamless Food Delivery with Our Advanced App
          </h2>
          <p>
            Elevate your food delivery service and achieve your business goals
            with our feature-rich and user-friendly Food Delivery App. We offer
            customization options to align the app with your brand and cater to
            your specific business needs.
          </p>
          {/* <div className="text-center">
            <button className="btn1 mb-2 mb-lg-0">Explore Feature</button>
            <button className="btn1">Try Demo</button>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default FoodDeliveryApp;
