import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ft_content">
                <div className="ft_logo">
                  <Link to="/">
                    <img src="assets/image/footer-logo.png" alt="" />{" "}
                  </Link>
                </div>
                <p>
                  Adret Software Services is one of the leading companies in the
                  web and software development industry. We have a team of
                  seasoned skills and creative minds who are deft at providing
                  perfect web solutions to give you a competitive edge.
                </p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="ft_content">
                <h4>Quick Menu</h4>
                <ul className="list-unstyled ft_links">
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/career">Careers</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <Link to="/terms-condition">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy"> Privacy Policy </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="ft_content ">
                <h4>Our Services</h4>
                <ul className="list-unstyled ft_links">
                  <li>
                    <Link to="/website-design"> Website Design</Link>
                  </li>
                  <li>
                    <Link to="/web-app-developement">
                      {" "}
                      Web Application Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/android-app-dev">Android Development</Link>
                  </li>
                  <li>
                    <Link to="/nodejs-dev">Node Js Development</Link>
                  </li>
                  <li>
                    <Link to="/wordpress-dev">Wordpress Development</Link>
                  </li>
                  <li>
                    <Link to="/digital-marketing"> Digital Marketing</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 ">
              <div className="ft_content ft_gap_left">
                <h4>Contact Info</h4>
                <ul className="list-unstyled ft_links2">
                  <li>
                    <Link to="mailto:info@adretsoftware.com">
                      <i className="fa fa-envelope"></i> info@adretsoftware.com
                    </Link>
                  </li>

                  <li>
                    <Link to="tel:+18885054030">
                      <span className="ft_call">
                        <img src="assets/image/call-bg3.gif" alt="" />
                      </span>
                      +1 888 505 4030
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="tel:+919432205976">
                      <span className="ft_call">
                        <img src="assets/image/call-bg1.gif" alt="" />
                      </span>
                      +91 94322 05976
                    </Link>
                  </li> */}
                 
                </ul>
              </div>
            </div>
          </div>
          <div className="ft_border"></div>
          <div className="copy_txt">
            <div className="row">
              <div className="col-lg-6 order-2 order-sm-2 order-lg-1">
                <p className="mb-0">
                  © 2024 Adret Software Services Pvt. Ltd. All rights reserved.
                </p>
              </div>
              <div className="col-lg-6 order-1 order-sm-1 order-lg-2">
                <div className="ft_socail">
                  <Link
                    to="https://www.facebook.com/AdretSoftwareServicePvtLtd"
                    target="_blank"
                  >
                    <i className="ri-facebook-fill"></i>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/adret-software-services"
                    target="_blank"
                  >
                    <i className="ri-linkedin-fill"></i>
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UC9PAKlTyzwICWxWajFrrNfg"
                    target="_blank"
                  >
                    <i className="ri-youtube-line"></i>
                  </Link>
                  <Link
                    to="https://in.pinterest.com/Adretsoftwareservices/_saved/"
                    target="_blank"
                  >
                    <i className="ri-pinterest-line"></i>
                  </Link>
                  <Link
                    to="https://www.instagram.com/adret.software/"
                    target="_blank"
                  >
                    <i className="ri-instagram-line"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
