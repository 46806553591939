import React, { useEffect } from "react";
import ToolsTechnology from "../../ToolsTechnology/ToolsTechnology";
import OurClient from "../../OurClient/OurClient";
import Faq from "../../Faq/Faq";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const data = {
  faqHeader: "Frequently Asked Questions - Digital Marketing Services",
  caption:
    "Explore the details of our comprehensive digital marketing services. If you have additional questions, feel free to reach out to our knowledgeable team.",
  faqContent: [
    {
      ques: "How Does SEO Contribute to Digital Marketing Success?",
      ans: "Search Engine Optimization (SEO) is a crucial component of digital marketing. It involves optimizing your website to rank higher in search engine results. By improving visibility, SEO increases organic traffic, builds credibility, and contributes to long-term online success.",
    },
    {
      ques: "What Role Does Social Media Play in Digital Marketing?",
      ans: "Social media is a powerful tool in digital marketing for building brand awareness, engaging with your audience, and driving website traffic. Effective social media strategies can enhance customer relationships, promote content, and create a loyal online community for your brand.",
    },
    {
      ques: "Can Email Marketing Benefit My Business?",
      ans: "Absolutely! Email marketing is a cost-effective way to nurture leads, communicate with your audience, and drive conversions. Well-crafted email campaigns can deliver personalized content, promotions, and valuable information, keeping your brand top-of-mind for your subscribers.",
    },
    {
      ques: "How Do Paid Advertising Campaigns Contribute to Digital Marketing Goals?",
      ans: "Paid advertising, whether through search engines, social media platforms, or display networks, allows for targeted and measurable campaigns. It can quickly increase brand visibility, drive traffic, and generate leads, making it a valuable component of a comprehensive digital marketing strategy.",
    },
  ],
};

export default function DigitalMarketing() {
  useEffect(() => {
    // Define dataLayer
    window.dataLayer = window.dataLayer || [];

    // Load GTM script
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=GTM-PB99PNC9";
    script.async = true;
    document.head.appendChild(script);

    // Initialize GTM
    window.dataLayer.push({ js: new Date() });
    window.dataLayer.push({ config: "GTM-PB99PNC9" });

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.adretsoftware.com/digital-marketing/"
        />
        <meta
          name="google-site-verification"
          content="googlebff0f5cdd84bde27.html"
        />
        <title>
          Best digital marketing agency in Kolkata | Adret software.
        </title>
        <meta
          property="og:title"
          content="Best digital marketing agency in Kolkata | Adret software."
        />
        <meta
          name="description"
          content="Adret Software stands out as one of the best digital marketing agency in Kolkata boost your brand's online presence with our best digital marketing services in Kolkata."
        />
        <meta
          property="og:description"
          content="Adret Software stands out as one of the best digital marketing agency in Kolkata boost your brand's online presence with our best digital marketing services in Kolkata."
        />
        <meta
          property="og:url"
          content="https://www.adretsoftware.com/digital-marketing/"
        />
        <meta
          property="og:site_name"
          content="Best digital marketing agency in Kolkata|Adret software."
        />
        <meta
          name="keywords"
          content="best digital marketing agency in Kolkata, best digital marketing company in Kolkata, best digital marketing services in kolkata, best digital marketing firm in kolkata"
        />
        <meta name="geo.position" content="22.591770,88.420189" />
        <meta name="keywords" content="Adret software" />
        <meta
          name="description"
          content="Adret Software stands out as one of the best digital marketing agency in Kolkata boost your brand's online presence with our best digital marketing services in Kolkata."
        />

        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PB99PNC9');
        `,
          }}
        ></script>
        {/* End Google Tag Manager */}

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "@id": "#Organization",
            url: "https://www.adretsoftware.com/digital-marketing",
            legalName: "Adret",
            name: "Adret digital marketing",
            description:
              "Adret Software stands out as one of the best digital marketing agencies in Kolkata, boosting your brand's online presence with our best digital marketing services.",
            logo: "",
            telephone: "9432205976",
            email: "info@adretsoftware.com",
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Unit No.206, Dn-10, Merlin Matrix, DN Block, Sector V, Bidhannagar, Kolkata",
              addressLocality: "Salt lake",
              addressRegion: "West Bengal",
              addressCountry: "India",
              postalCode: "700091",
            },
            sameAs: [
              "https://www.facebook.com/AdretSoftwareServicePvtLtd",
              "https://www.youtube.com/channel/UCq7KSxHDApVyiM3Ypqp8PZQ",
              "https://www.instagram.com/adret.software/?hl=en",
              "https://www.linkedin.com/company/adret-software-services",
              "https://twitter.com/softwareadret/status/1407960101432954885",
            ],
          })}
        </script>

        {/* Google Tag Manager (noscript) */}
        <noscript>{`
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PB99PNC9" 
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>
      `}</noscript>
        {/* End Google Tag Manager (noscript) */}
      </Helmet>
      <div className="inner-page" id="digital-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Best digital marketing agency in Kolkata</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                    <Link to="/">
                          <i className="ri-home-4-line"></i>Home
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Digital Marketing
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="bg-body-tertiary">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div
                  className="heading"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                 <h2 style={{textTransform: "capitalize"}}>One of the top digital marketing services in kolkata</h2>

                  <p>
                  Adret Software stands out as one of the top digital marketing services in Kolkata, offering a comprehensive suite of solutions to elevate your online presence. With a proven track record, we combines innovation and expertise to deliver results-driven strategies that propel businesses forward. As a leading player in the digital realm, our adept team crafts tailored campaigns, ensuring maximum impact and visibility. Trust Adret Software for cutting-edge solutions that define excellence in the realm of digital marketing services in Kolkata. Elevate your brand with the assurance of best strategies tailored just for you
                  </p>

                  <div
                    className="btn_sec"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Link to="/contact" className="btn3 btn_gap">
                      What's Your Project
                    </Link>
                    <Link to="/portfolio" className="btn3">
                      Our Creation
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src="https://www.webguru-india.com/ecommerce-mobile-app/images/bnrimg.png"
                  className="img-fluid"
                  alt=""
                  data-aos="fade-up"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color1"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div
          className="container pt-5"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <h2 className="section-title text-start py-3">
            Our Bespoke Digital Marketing Services
          </h2>

          <p className="">
            Adret has helped hundreds of businesses grow their online customers
            base, and help you too. Our digital marketing experts can craft
            tactical solutions for your brand that are guaranteed to positively
            affect your ROI.
          </p>
        </div>
        <div className="details-website">
          <div className="container pb-5">
            <div className="row align-items-center">
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <div className="py-lg-3 py-2">
                  <div className="d-flex">
                    <img
                      width="50"
                      height="50"
                      className="mr-3"
                      src="https://img.icons8.com/ios/50/0d6efd/web.png"
                      alt="web"
                    />
                    <h5 className="mb-0">User First Strategy - UI/UX</h5>
                  </div>
                  <p>
                    We consider the needs and preferences of the users while
                    planning an app development strategy. If they find your app
                    easy to operate, its popularity will grow and it’ll cut
                    through the competition.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                <div className="py-lg-3 py-2">
                  <div className="d-flex align-items-center">
                    <img
                      width="50"
                      height="50"
                      className="mr-3"
                      src="https://img.icons8.com/ios-filled/50/0d6efd/media-queries.png"
                      alt="media-queries"
                    />
                    <p className="details-heading">
                      Adaptability - Screen Sizes & Devices
                    </p>
                  </div>
                  <p>
                    We build the interface of application adaptable to
                    variations. We make sure that the text is readable and the
                    navigation fits within the screen for a wide range of makes
                    and models.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div className="py-lg-3 py-2">
                  <div className="d-flex align-items-center">
                    <img
                      width="50"
                      height="50"
                      className="mr-3"
                      src="https://img.icons8.com/ios-glyphs/50/0d6efd/stationery.png"
                      alt="stationery"
                    />
                    <p className="details-heading">Minimize Action Sequences</p>
                  </div>
                  <p>
                    Reducing the number of actions in an app effectively helps
                    the users to accomplish a task quickly. Hence, we do the
                    needful to simplify the user journey in the app.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <div className="py-lg-3 py-2">
                  <div className="d-flex align-items-center">
                    <img
                      width="50"
                      height="50"
                      className="mr-3"
                      src="https://img.icons8.com/ios/50/0d6efd/cyber-security.png"
                      alt="cyber-security"
                    />
                    <p className="details-heading">Security</p>
                  </div>
                  <p>
                    Our mobile app makers implement all the safety measures to
                    protect sensitive data shared by the users. Further, we
                    disclose the purpose of collecting every data to make the
                    users feel comfortable using the app.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                <div className="py-lg-3 py-2">
                  <div className="d-flex align-items-center">
                    <img
                      src="assets/image/brand-image.png"
                      className="mr-3"
                      alt=""
                    />
                    <p className="details-heading">Brand Focused</p>
                  </div>
                  <p>
                    Top mobile app development services are always tailored as
                    per your requirement. We research your business extensively
                    before starting the development process.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div className="py-lg-3 py-2">
                  <div className="d-flex align-items-center">
                    <img
                      width="50"
                      height="50"
                      className="mr-3"
                      src="https://img.icons8.com/ios-filled/50/0d6efd/commercial.png"
                      alt="commercial"
                    />
                    <p className="details-heading">Marketing & Promotion</p>
                  </div>
                  <p>
                    Our app store optimization services help your application to
                    stand out from the crowd in the app market of the iOS and
                    Android platforms.
                  </p>
                </div>
              </div>
              <p>
                Node JS stands out as a technically advanced application
                development platform for both small and large scale businesses.
                Opt for our Node JS development services for robust and
                fast-operating web and mobile applications.
              </p>
            </div>
            <div
              className="btn_sec"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <Link to="/contact" className="btn3 btn_gap">
                What's Your Project
              </Link>
              <Link to="/portfolio" className="btn3">
                Our Creation
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading">
            <h2>Count On Us When You Hire Mobile App Developers</h2>
            <p className="section-subtitle">
              Are you looking to hire mobile app developers from India at a
              competitive rate? Here are a few of many reasons for picking us as
              your trusted app development company.
            </p>
          </div>
        </div>

        <div className="details-website">
          <div className="container pb-5">
            <div className="row g-4">
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <div className="moblile_app_con">
                  <div>
                    <i className="ri-javascript-line icon-style icon-color img-fluid text-white border-white"></i>
                    <h4>Push Notifications</h4>
                  </div>
                  <p>
                    Keep your users updated with the latest offers or progress
                    of any service through push notification and drive user
                    engagement.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="300"
              >
                <div className="moblile_app_con">
                  <div>
                    <i className="ri-timer-flash-line icon-style icon-color img-fluid text-white border-white"></i>
                    <h4>Social Media Integration</h4>
                  </div>
                  <p>
                    Social integration simplifies the process of signup and also
                    allows the users to share your app / service / product on
                    varied channels. This actively encourages brand promotion.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                <div className="moblile_app_con">
                  <div>
                    <i className="ri-check-double-line icon-style icon-color img-fluid text-white border-white"></i>
                    <h4>Payment Gateway Integration</h4>
                  </div>
                  <p>
                    Integrating a secure payment gateway into the app helps the
                    online shoppers to checkout seamlessly resulting in more
                    conversion for the business.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="100"
              >
                <div className="moblile_app_con">
                  <div>
                    <i className="ri-tools-line icon-color icon-style img-fluid text-white border-white"></i>
                    <h4>User Feedback</h4>
                  </div>
                  <p>
                    Feedback system integrated into an app helps the users to
                    report bugs, complaints or suggestions. Handling such
                    feedback with care helps to build trust and credibility.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="btn_sec"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <Link to="/contact" className="btn2 btn_gap">
                What's Your Project
              </Link>
              <Link to="/portfolio" className="btn2">
                Our Creation
              </Link>
            </div>
          </div>
        </div>
      </section>

      <ToolsTechnology />
      <OurClient />

      <Faq data={data} />

      <section className="section " data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="row">
            <div
              className="col-md-7 blog-left wow fadeInLeft"
              data-wow-duration="0.5s"
              data-wow-delay="0.0s"
            >
              <h2 className="section-title">From Our Blog</h2>
              <div className="blog-inner">
                <h4 className="section-subtitle">
                  Top Technologies and Trends to Guide Mobile App Development
                </h4>
                <p>
                  Over the past decade or so, there has been a significant
                  growth in the use of mobile devices, especially smartphones.
                  Today, nearly 6.92 billion people worldwide use smartphones.It
                  translates to roughly 85.82 percent of the world’s population
                  (source: Statista).
                </p>
                <Link to="/blogs" className="btn1">
                  Read Blog Post
                </Link>
              </div>
            </div>
            <div
              className="col-md-5 hidden-xs blog-right wow fadeInRight"
              data-wow-duration="0.5s"
              data-wow-delay="0.0s"
            >
              <div className="blog-image-holder">
                <img
                  className="msd-lazyload loaded"
                  src="https://www.webguru-india.com/assets/images/digital-marketing-services-blog.jpg"
                  alt="blog-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
