import { useEffect } from 'react';

const useScrollToTopOnRefresh = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();

    const handleBeforeUnload = () => {
      scrollToTop();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useScrollToTopOnRefresh;
