import React from "react";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import Faq from "../Faq/Faq";

const data = {
  faqHeader: "Frequently Asked Questions - Hire Angular Developer",
  caption:
    "Explore the details of hiring Angular developers from our skilled team. Should you have additional inquiries or wish to discuss your project specifics, don't hesitate to contact us.",
  faqContent: [
    {
      ques: "What expertise should I seek when hiring an Angular developer?",
      ans: "When hiring an Angular developer, it's essential to look for expertise in TypeScript and JavaScript, proficiency in Angular framework (preferably Angular 2+), understanding of reactive programming and observables, knowledge of HTML, CSS, and front-end development principles, familiarity with RESTful APIs and asynchronous programming, and experience with version control systems like Git.",
    },
    {
      ques: "Do you provide flexible engagement models for Angular developers?",
      ans: "Yes, we offer flexible engagement models tailored to your project needs. Whether you require a dedicated Angular developer for a long-term project or prefer hourly-based arrangements for short-term tasks, we can accommodate your requirements.",
    },
    {
      ques: "Can your Angular developers work remotely?",
      ans: "Certainly! Our Angular developers are adept at remote collaboration and can effectively work with your team irrespective of geographical barriers. We utilize communication tools and project management systems to ensure seamless coordination and timely project delivery.",
    },
    {
      ques: "How do you ensure the quality of Angular app development?",
      ans: "We maintain a stringent quality assurance process throughout Angular app development. Our developers adhere to coding standards, conduct comprehensive testing, follow best practices for performance optimization and security, and strive to deliver Angular applications that are robust, scalable, and aligned with your project objectives.",
    },
  ],
};

export default function HireAngularDev() {
  return (
    <>
      <div
        className="inner-page"
        id="hire-us-bg"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Hire Angular Developer</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line"></i>Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hire Angular Developer
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading row_gaping">
            <h2 className="section-title-2 text-center">
              Engage Angular Experts at the Best rates
            </h2>
          </div>
          <div className="center-row row row-below g-2 row-cols-lg-6 row-cols-sm-3 row-cols-2">
            <div className="col-md text-center">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-1.png" alt="" />
                <h5>One Week Free Trial</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-2.png" alt="" />
                <h5>Minimum 3+ Years of Experience</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-3.png" alt="" />
                <h5>Non-Disclosure Agreement</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-4.png" alt="" />

                <h5>Flexible Hiring Models</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-5.png" alt="" />

                <h5>Competitive Rate</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-6.png" alt="" />
                <h5>Source Code Delivery</h5>
              </div>
            </div>
          </div>
          <div className="btn_sec text-center row-below">
            <Link to="/contact" className="btn1 btn_gap">
              Request a quote
            </Link>
            <Link to="/portfolio" className="btn1">
              Our Creation
            </Link>
          </div>
        </div>
      </section>

      <section
        className="section hire_content_anguler"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="small-hire ">
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <div className="small_hire_content heading">
                <h3>WHAT CAN WE DO FOR YOU?</h3>
                <p>
                  We would be happy to discuss the project with you in person
                </p>
                <h4>
                  Call us on <Link to="tel:+18885054030">+1 888 505 4030</Link>
                </h4>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    DROP IN A MESSAGE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="section-title">
              Hire Offshore Angular Developers to Meet <br /> Custom
              Requirements
            </h2>
            <p className="section-subtitle pt-3">
              Our wide suite of Angular development services can meet your most
              specific demand. Depending on your budget and needs, you can hire
              Angular experts from us on a full-time and part-time basis.
            </p>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-7.png" alt="" />
                <h6 className="details-heading">Custom Web App Development</h6>
                <p>
                  Engage our team for custom web application development
                  services. Our developers make extensive use of Angular code
                  library to build feature-rich web applications having
                  beautiful graphics and transition effects in quick time. We
                  also provide CSR/SSR implementation as per your project
                  requirements.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-8.png" alt="" />
                <h6 className="details-heading">API Integration</h6>
                <p className="">
                  Our experienced Angular developers can integrate custom APIs
                  into an application to extend its usability. From shipping to
                  payment gateways to calendars to live chat - we can integrate
                  APIs for a wide range of functionalities.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-9.png" alt="" />
                <h6 className="details-heading">Ajax Integration</h6>
                <p className="">
                  Our Angular developers prefer to leverage Ajax techniques in
                  dynamic web development. With adequate efficiency in
                  JavaScript and HTTP Client Request, our team can skilfully
                  create asynchronous web apps. We also integrate Redux, Ngrx,
                  or RxJs state management stores as per project requirements.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-10.png" alt="" />
                <h6 className="details-heading">Migration</h6>
                <p className="">
                  Hire remote Angular developers to migrate a web application to
                  the latest version of Angular. We adopt relevant migration
                  strategies to simplify the process. Our full-proof migration
                  approach prevents security issues and data loss.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white  req-card ">
                <img src="assets/image/hire-angular-icon-11.png" alt="" />
                <h6 className="details-heading">Support & Maintenance</h6>
                <p className="py-2">
                  Hire dedicated Angular developers from us to maintain a web
                  application. From feature addition to performance optimization
                  and security enhancement - we deliver a full-scale maintenance
                  service to the clients.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-12.png" alt="" />
                <h6 className="details-heading">Ecommerce Development</h6>
                <p className="py-2">
                  Hire offshore Angular developers from India to create robust
                  and conversion-driven B2B and B2C eCommerce applications. Our
                  tailored online stores are equipped with the latest features
                  and functionalities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading  text-center">
            <h2>Why Choose Us</h2>
          </div>
          <div className="row g-3 ">
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-13.png" alt="" />
                </div>
                <h5 className="my-3">Industry Experts</h5>
                <p>
                  Our in-house team is enriched with trusted and experienced
                  developers - no freelancer.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-14.png" alt="" />
                </div>
                <h5 className="my-3">Widely recommended</h5>
                <p>
                  We got hundreds of real 5-star ratings and reviews on Google,
                  GoodFirms, and Clutch combined.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-15.png" alt="" />
                </div>
                <h5 className="my-3">Customer Service</h5>
                <p>
                  Our zero complaint work approach helps us to ensure a seamless
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-16.png" alt="" />
                </div>
                <h5 className="my-3">Ideal Workplace</h5>
                <p>
                  We cultivate a productive work environment with a trendy setup
                  to enhance the outcomes.
                </p>
              </div>
            </div>
            <div className="row-below btn_sec text-center">
              <Link to="/contact" className="btn2 btn_gap">
                Get Started
              </Link>
              <Link to="/clients" className="btn2">
                Our Clients
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Slider />

      <Faq data={data} />
    </>
  );
}
