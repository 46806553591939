import React,{useState} from "react";
import OurClient from "../OurClient/OurClient";
import { Link } from "react-router-dom";

export default function GllPrice() {
  const [price, setPrice] = useState({ silver: 399, gold: 599,platinum:799 });
  const [symbol, setSymbol] = useState(true);
    const handleToggle = () => {    
        let rate = 0.93;
        setSymbol(!symbol);
        if (symbol === true) {
          setPrice({
            silver: Math.round(399* rate),
            gold: Math.round(599 * rate),
            platinum: Math.round(799 * rate),
          });
        } else {
          setPrice({
            silver: 399,
            gold: 599,
            platinum:799
          });
        }
      };
  return (
    <>
      <div className="inner-page" id="contact-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>GLL</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="ri-home-4-line"></i> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      GLL
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="seo_content_sec">
        <div className="container">
        <div className="row">
                    <div className="col-g-12 text-right">
                        <div className="switch_butt">
                            <div className="form-check form-switch">
                                <label>$</label>
                                <input onClick={handleToggle} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                <label>€</label>
                              </div>
                        </div>
                       
                    </div>
                </div>
          <div className="row g-3 justify-content-center mt-3">
            <div className="col-lg-4">
              <div
                className="pricing_card"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="top_header">
                  <h4>Sliver</h4>
                  <div className="border_btn"></div>
                  <div className="pirze_sec">
                  {symbol ? "$" : "€"}
                            <span>{price.silver}</span>
                  </div>

                  <div className="down_arow">
                    <div className="down_arow_cont"></div>
                    <i className="fa fa-arrow-down"></i>
                  </div>
                  {/* <div className="over_heading">
                    <span>Silver</span>
                  </div> */}
                </div>
                <div className="pricing_body">
                  <ul className="pricing_list">
                    <li>
                      <i className="fa fa-check"></i>5 No of Keywords for 1 year
                    </li>
                    <li>
                      <i className="fa fa-check"></i>3 Months Timeline
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Set up Google business page
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Business Directory Listings
                    </li>
                    <li>
                      <i className="fa fa-check"></i>5 Classified Ads
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Google plus posts share
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Delivery Report
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Activities
                      Monitoring
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Profile Improvement
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Dedicated Account Managers
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Customer Support (Email,
                      Chat & Telephone)
                    </li>
                  </ul>
                  <Link to="" className="btn_buy">
                    BUY NOW
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="pricing_card"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="top_header">
                  <h4>Gold</h4>
                  <div className="border_btn"></div>
                  <div className="pirze_sec">
                  {symbol ? "$" : "€"}
                            <span>{price.gold}</span>
                  </div>

                  <div className="down_arow">
                    <div className="down_arow_cont"></div>
                    <i className="fa fa-arrow-down"></i>
                  </div>
                  {/* <div className="over_heading">
                    <span>Gold</span>
                  </div> */}
                </div>
                <div className="pricing_body">
                  <ul className="pricing_list">
                    <li>
                      <i className="fa fa-check"></i>5 No of Keywords for 2 year
                    </li>
                    <li>
                      <i className="fa fa-check"></i>3 Months Timeline
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Set up Google business page
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Business Directory Listings
                    </li>
                    <li>
                      <i className="fa fa-check"></i>5 Classified Ads
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Google plus posts share
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Google plus review posting
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Delivery Report
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Activities
                      Monitoring
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Profile Improvement
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Dedicated Account Managers
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Customer Support (Email,
                      Chat & Telephone)
                    </li>
                  </ul>
                  <Link to="" className="btn_buy">
                    BUY NOW
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div
                className="pricing_card"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="top_header">
                  <h4>Platinum</h4>
                  <div className="border_btn"></div>
                  <div className="pirze_sec">
                  {symbol ? "$" : "€"}
                            <span>{price.platinum}</span>
                  </div>

                  <div className="down_arow">
                    <div className="down_arow_cont"></div>
                    <i className="fa fa-arrow-down"></i>
                  </div>
                  {/* <div className="over_heading">
                    <span>Platinum</span>
                  </div> */}
                </div>
                <div className="pricing_body">
                  <ul className="pricing_list">
                    <li>
                      <i className="fa fa-check"></i>5 No of Keywords for 3 year{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>3 Months Timeline{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Set up Google business page{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Business Directory Listings{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>5 Classified Ads{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Google plus posts share{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Google plus review posting{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Posting Reviews{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Delivery Report{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Social Activities
                      Monitoring{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Profile Improvement{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Dedicated Account Managers{" "}
                    </li>
                    <li>
                      <i className="fa fa-check"></i>Customer Support (Email,
                      Chat & Telephone){" "}
                    </li>
                  </ul>
                  <Link to="" className="btn_buy">
                    BUY NOW
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="logo_design_project p-0" id="android_app">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img aos-init aos-animate"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/call-suprot-img.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7 text-center">
            <div
              className="heading aos-init aos-animate"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h3> Support For 24/7</h3>
              <p>
                What can we do for you? We would be happy to discuss the project
                with you in person
              </p>
              <div className="btn_sec">
                <Link to="/contact" className="btn2">
                  Request a quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OurClient/>
    </>
  );
}
