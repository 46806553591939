import React from "react";
import { Link } from "react-router-dom";
import OurClient from "../OurClient/OurClient";
import Slider from "../Slider/Slider";
import Faq from "../Faq/Faq";
const data = {
  faqHeader: "Frequently Asked Questions - Hire iOS Developer",
  caption:
    "Explore the details of hiring iOS developers from our proficient team. Should you have additional inquiries or wish to discuss your project specifics, feel free to reach out to us.",
  faqContent: [
    {
      ques: "What skills are important when hiring an iOS developer?",
      ans: "When hiring an iOS developer, it's crucial to look for expertise in Swift or Objective-C programming languages, familiarity with iOS SDK, UIKit, and Core Data frameworks, experience with Xcode IDE, understanding of Apple's Human Interface Guidelines, knowledge of RESTful APIs for network communication, and proficiency in debugging and performance optimization.",
    },
    {
      ques: "Do you offer flexible engagement models for iOS developers?",
      ans: "Yes, we provide flexible engagement models tailored to your project requirements. Whether you need a dedicated iOS developer for a long-term project or prefer hourly-based arrangements for short-term tasks, we can accommodate your needs.",
    },
    {
      ques: "Can your iOS developers work remotely?",
      ans: "Absolutely! Our iOS developers are experienced in remote collaboration and can effectively work with your team regardless of geographical location. We utilize communication tools and project management systems to ensure seamless coordination and timely project delivery.",
    },
    {
      ques: "How do you ensure the quality of iOS app development?",
      ans: "Quality assurance is paramount in our iOS app development process. Our developers adhere to Apple's best practices and guidelines, conduct rigorous testing using XCTest and other testing frameworks, follow coding standards for maintainability and scalability, and prioritize user experience to deliver high-quality, reliable iOS applications.",
    },
  ],
};

export default function HireIosDev() {
  return (
    <>
      <div
        className="inner-page"
        id="hire-us-bg"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Hire iOS development</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line"></i>Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hire iOS Developer
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading row_gaping">
            <h2 className="section-title-2 text-center">
              Engage iOS app Experts at the Best rates
            </h2>
          </div>
          <div className="center-row row row-below g-2 row-cols-lg-6 row-cols-sm-3 row-cols-2">
            <div className="col-md text-center">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-1.png" alt="" />
                <h5>One Week Free Trial</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-2.png" alt="" />
                <h5>Minimum 3+ Years of Experience</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-3.png" alt="" />
                <h5>Non-Disclosure Agreement</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-4.png" alt="" />

                <h5>Flexible Hiring Models</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-5.png" alt="" />

                <h5>Competitive Rate</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-6.png" alt="" />
                <h5>Source Code Delivery</h5>
              </div>
            </div>
          </div>
          <div className="btn_sec text-center row-below">
            <Link to="/contact" className="btn2 btn_gap">
              Get Started
            </Link>
            <Link to="/clients" className="btn2">
              Our Clients
            </Link>
          </div>
        </div>
      </section>

      <section
        className="section hire_content_anguler"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="small-hire ">
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <div className="small_hire_content heading">
                <h3>WHAT CAN WE DO FOR YOU?</h3>
                <p>
                  We would be happy to discuss the project with you in person
                </p>
                <h4>
                  Call us on <Link to="tel:+18885054030">+1 888 505 4030</Link>
                </h4>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    DROP IN A MESSAGE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="section-title">
              Hire Offshore iOS to Meet <br /> Custom Requirements
            </h2>
            <p className="section-subtitle pt-3">
              Elevate your iOS app development projects by hiring our skilled
              offshore iOS developers. Our experienced team is ready to meet
              your specific requirements, providing efficient and cost-effective
              solutions. With flexible engagement models, we cater to the
              complexity and duration of your projects, ensuring a seamless
              collaboration.
            </p>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-7.png" alt="" />
                <h6 className="details-heading">Custom iOS Development</h6>
                <p>
                  Empower your digital journey with our custom iOS app
                  development services. At [Your Company Name], our skilled
                  developers specialize in crafting bespoke solutions that
                  precisely align with your unique business needs. Whether you
                  need a feature-rich mobile app, a dynamic business
                  application, or a captivating user interface, we tailor our
                  iOS expertise to meet your goals.{" "}
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-8.png" alt="" />
                <h6 className="details-heading">API Integration</h6>
                <p className="">
                  Enhance the functionality of your iOS with our expert API
                  integration services. Our skilled Android developers
                  seamlessly integrate third-party APIs, ensuring a smooth flow
                  of data and expanded features. Whether it's incorporating
                  payment gateways, social media APIs, or any other external
                  service, we tailor the integration to align seamlessly with
                  your app's environment.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-9.png" alt="" />
                <h6 className="details-heading">Ajax Integration</h6>
                <p className="">
                  Elevate the interactivity of your mobile applications with our
                  Ajax integration services. Our skilled app developers
                  seamlessly incorporate asynchronous requests, allowing for
                  real-time updates and dynamic content loading without
                  disrupting the user experience. Whether it's enhancing user
                  engagement through live updates or optimizing form
                  submissions, our Ajax integration ensures a smoother and more
                  responsive app interaction.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-10.png" alt="" />
                <h6 className="details-heading">Migration</h6>
                <p className="">
                  Embark on a smooth and efficient transition for your iOS
                  applications with our expert migration services. Whether
                  you're upgrading to the latest iOS version, migrating from
                  another platform, or enhancing features, our skilled team
                  ensures a seamless process. We specialize in preserving data
                  integrity, optimizing performance, and minimizing downtime,
                  providing a secure and reliable outcome.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white  req-card ">
                <img src="assets/image/hire-angular-icon-11.png" alt="" />
                <h6 className="details-heading">Support & Maintenance</h6>
                <p className="py-2">
                  Ensure the continuous optimal performance of your digital
                  assets with our dedicated support and maintenance services.
                  Our expert team is committed to promptly resolving issues,
                  applying updates, and proactively monitoring for potential
                  challenges. We offer tailored support plans to meet the
                  specific needs of your projects, ensuring they stay secure,
                  up-to-date, and scalable.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-12.png" alt="" />
                <h6 className="details-heading">Ecommerce Development</h6>
                <p className="py-2">
                  Transform your online business into a seamless and efficient
                  platform with our expert Ecommerce development services. At
                  adret software, our skilled developers specialize in creating
                  robust and scalable solutions tailored to your unique business
                  needs. From intuitive shopping cart experiences to secure
                  payment gateways, we ensure a user-friendly Ecommerce platform
                  that enhances customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading  text-center">
            <h2>Why Choose Us</h2>
          </div>
          <div className="row g-3 ">
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-13.png" alt="" />
                </div>
                <h5 className="my-3">Industry Experts</h5>
                <p>
                  Our in-house team is enriched with trusted and experienced
                  developers - no freelancer.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-14.png" alt="" />
                </div>
                <h5 className="my-3">Widely recommended</h5>
                <p>
                  We got hundreds of real 5-star ratings and reviews on Google,
                  GoodFirms, and Clutch combined.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-15.png" alt="" />
                </div>
                <h5 className="my-3">Customer Service</h5>
                <p>
                  Our zero complaint work approach helps us to ensure a seamless
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-16.png" alt="" />
                </div>
                <h5 className="my-3">Ideal Workplace</h5>
                <p>
                  We cultivate a productive work environment with a trendy setup
                  to enhance the outcomes.
                </p>
              </div>
            </div>
            <div className="row-below btn_sec text-center">
              <Link to="/contact" className="btn2 btn_gap">
                Get Started
              </Link>
              <Link to="/clients" className="btn2">
                Our Clients
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section
        className="testimonials_sec"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <Slider />
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="owl-carousel owl-theme review_slider">
                <div className="item">
                  <div
                    className="testimonials_content"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    <div className="contented">
                      <div className="top_content">
                        <div className="coma_sty">
                          <img
                            src="assets/image/home-new-quotetion.svg"
                            alt=""
                          />
                        </div>
                        <div className="content_profile">
                          <img
                            src="assets/image/home-new-testi-img.png"
                            alt=""
                          />
                        </div>
                      </div>

                      <p>
                        About adret I have bunch of things to share. But in a
                        nutshell..... Awesome experience. Adret always work with
                        a clear vision to client satisfaction as well as
                        employee satisfaction. Being a part of Adret Software. I
                        can say best company to work.
                      </p>

                      <h4>- Yashwant Singh</h4>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div
                    className="testimonials_content"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    <div className="contented">
                      <div className="top_content">
                        <div className="coma_sty">
                          <img
                            src="assets/image/home-new-quotetion.svg"
                            alt=""
                          />
                        </div>
                        <div className="content_profile">
                          <img
                            src="assets/image/home-new-testi-img.png"
                            alt=""
                          />
                        </div>
                      </div>

                      <p>
                        About adret I have bunch of things to share. But in a
                        nutshell..... Awesome experience. Adret always work with
                        a clear vision to client satisfaction as well as
                        employee satisfaction. Being a part of Adret Software. I
                        can say best company to work.
                      </p>

                      <h4>- Yashwant Singh</h4>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div
                    className="testimonials_content"
                    data-aos="zoom-in"
                    data-aos-duration="2000"
                  >
                    <div className="contented">
                      <div className="top_content">
                        <div className="coma_sty">
                          <img
                            src="assets/image/home-new-quotetion.svg"
                            alt=""
                          />
                        </div>
                        <div className="content_profile">
                          <img
                            src="assets/image/home-new-testi-img.png"
                            alt=""
                          />
                        </div>
                      </div>

                      <p>
                        About adret I have bunch of things to share. But in a
                        nutshell..... Awesome experience. Adret always work with
                        a clear vision to client satisfaction as well as
                        employee satisfaction. Being a part of Adret Software. I
                        can say best company to work.
                      </p>

                      <h4>- Yashwant Singh</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Faq data={data} />
    </>
  );
}
