import React from "react";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import Faq from "../Faq/Faq";

const data = {
  faqHeader: "Frequently Asked Questions - Hire WordPress Developer",
  caption:
    "Explore the details of hiring WordPress developers from our skilled team. Should you have additional inquiries or wish to discuss your project specifics, feel free to reach out to us.",
  faqContent: [
    {
      ques: "What skills should I look for when hiring a WordPress developer?",
      ans: "When hiring a WordPress developer, it's important to look for expertise in PHP programming language, proficiency in WordPress CMS, including theme and plugin development, knowledge of HTML, CSS, and JavaScript for front-end customization, understanding of MySQL database, experience with debugging and troubleshooting WordPress issues, and familiarity with version control systems like Git.",
    },
    {
      ques: "Do you provide flexible engagement models for WordPress developers?",
      ans: "Yes, we offer flexible engagement models tailored to your project requirements. Whether you need a dedicated WordPress developer for a long-term project or prefer hourly-based arrangements for short-term tasks, we can accommodate your needs.",
    },
    {
      ques: "Can your WordPress developers work remotely?",
      ans: "Absolutely! Our WordPress developers are experienced in remote collaboration and can effectively work with your team regardless of geographical location. We utilize communication tools and project management systems to ensure seamless coordination and timely project delivery.",
    },
    {
      ques: "How do you ensure the quality of WordPress development?",
      ans: "Quality assurance is a priority in our WordPress development process. Our developers follow WordPress coding standards, conduct thorough testing, including functional and performance testing, adhere to best practices for security and SEO optimization, and strive to deliver high-quality, scalable WordPress websites that meet your business requirements.",
    },
  ],
};

export default function HireWordpressDev() {
  return (
    <>
      <div
        className="inner-page"
        id="hire-us-bg"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Hire WordPress Developer</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line"></i>Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hire Word Press Developer
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading row_gaping">
            <h2 className="section-title-2 text-center">
              Engage WordPress Experts at the Best rates
            </h2>
          </div>
          <div className="center-row row row-below g-2 row-cols-lg-6 row-cols-sm-3 row-cols-2">
            <div className="col-md text-center">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-1.png" alt="" />
                <h5>One Week Free Trial</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-2.png" alt="" />
                <h5>Minimum 3+ Years of Experience</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-3.png" alt="" />
                <h5>Non-Disclosure Agreement</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-4.png" alt="" />

                <h5>Flexible Hiring Models</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-5.png" alt="" />

                <h5>Competitive Rate</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-6.png" alt="" />
                <h5>Source Code Delivery</h5>
              </div>
            </div>
          </div>
          <div className="btn_sec text-center row-below">
            <Link to="/contact" className="btn2 btn_gap">
              Get Started
            </Link>
            <Link to="/clients" className="btn2">
              Our Clients
            </Link>
          </div>
        </div>
      </section>

      <section
        className="section hire_content_anguler"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="small-hire ">
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <div className="small_hire_content heading">
                <h3>WHAT CAN WE DO FOR YOU?</h3>
                <p>
                  We would be happy to discuss the project with you in person
                </p>
                <h4>
                  Call us on <Link to="tel:+18885054030">+1 888 505 4030</Link>
                </h4>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    DROP IN A MESSAGE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="section-title">
              Hire Offshore WordPress to Meet <br /> Custom Requirements
            </h2>
            <p className="section-subtitle pt-3">
              Unlock a world of WordPress expertise by hiring our offshore team
              for your projects. Our seasoned WordPress developers bring a
              wealth of experience and skill to the table, ensuring high-quality
              solutions tailored to your specific requirements. With flexible
              engagement models, we cater to the complexity and duration of your
              projects while offering cost-effective offshore development.
            </p>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-7.png" alt="" />
                <h6 className="details-heading">
                  Custom WordPress Development
                </h6>
                <p>
                  Elevate your online presence with our custom WordPress
                  development services. Adret software, our seasoned WordPress
                  developers specialize in creating bespoke solutions that
                  precisely match your unique business needs. Whether you
                  require a custom theme, plugin development, or a fully
                  customized website, we ensure a seamless integration of
                  functionality and design.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-8.png" alt="" />
                <h6 className="details-heading">API Integration</h6>
                <p className="">
                  Enhance the functionality of your WordPress website with our
                  expert API integration services. Our skilled team seamlessly
                  integrates third-party APIs, ensuring a smooth flow of data
                  and expanded features. Whether incorporating payment gateways,
                  social media APIs, or any other external service, we tailor
                  the integration to align seamlessly with your WordPress
                  environment.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-9.png" alt="" />
                <h6 className="details-heading">Ajax Integration</h6>
                <p className="">
                  Elevate your WordPress website's interactivity with our Ajax
                  integration services. Our skilled team seamlessly incorporates
                  asynchronous requests, allowing for real-time updates and
                  dynamic content loading without page reloads. Whether it's
                  enhancing user engagement through live updates or optimizing
                  form submissions, our Ajax integration ensures a smoother and
                  more responsive user experience.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-10.png" alt="" />
                <h6 className="details-heading">Migration</h6>
                <p className="">
                  Upgrade your WordPress website effortlessly with our expert
                  migration services. Whether you're transitioning to the latest
                  WordPress version, migrating from another platform, or
                  undertaking a complex data transfer, our skilled team ensures
                  a smooth and secure migration process. We specialize in
                  preserving data integrity, optimizing performance, and
                  implementing the latest features of WordPress.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white  req-card ">
                <img src="assets/image/hire-angular-icon-11.png" alt="" />
                <h6 className="details-heading">Support & Maintenance</h6>
                <p className="py-2">
                  Ensure the continuous optimal performance of your WordPress
                  website with our dedicated support and maintenance services.
                  Our expert team is committed to promptly resolving issues,
                  applying updates, and proactively monitoring for potential
                  challenges. We offer tailored support plans to meet the
                  specific needs of your WordPress project, ensuring it stays
                  secure, up-to-date, and scalable.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-12.png" alt="" />
                <h6 className="details-heading">Ecommerce Development</h6>
                <p className="py-2">
                  Revolutionize your online business with our expert WordPress
                  Ecommerce development services. Our skilled developers
                  specialize in creating robust, scalable, and feature-rich
                  Ecommerce solutions tailored to your unique business needs.
                  From seamless shopping cart experiences to secure payment
                  gateways, we ensure a user-friendly platform that enhances
                  customer satisfaction
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading  text-center">
            <h2>Why Choose Us</h2>
          </div>
          <div className="row g-3 ">
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-13.png" alt="" />
                </div>
                <h5 className="my-3">Industry Experts</h5>
                <p>
                  Our in-house team is enriched with trusted and experienced
                  developers - no freelancer.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-14.png" alt="" />
                </div>
                <h5 className="my-3">Widely recommended</h5>
                <p>
                  We got hundreds of real 5-star ratings and reviews on Google,
                  GoodFirms, and Clutch combined.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-15.png" alt="" />
                </div>
                <h5 className="my-3">Customer Service</h5>
                <p>
                  Our zero complaint work approach helps us to ensure a seamless
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-16.png" alt="" />
                </div>
                <h5 className="my-3">Ideal Workplace</h5>
                <p>
                  We cultivate a productive work environment with a trendy setup
                  to enhance the outcomes.
                </p>
              </div>
            </div>
            <div className="row-below btn_sec text-center">
              <Link to="/contact" className="btn2 btn_gap">
                Get Started
              </Link>
              <Link to="/clients" className="btn2">
                Our Clients
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Slider />

      <Faq data={data} />
    </>
  );
}
