import React, { useEffect, useState } from "react";
import { CountUp } from "countup.js";
// import OwlCarousel from 'react-owl-carousel';
import AOS from "aos";
import "aos/dist/aos.css";
import "./assets/css/font-awesome.min.css"
import "./assets/css/bootstrap.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/owl.carousel.min.css";

import Rout from "./Router/Router";
import useScrollToTopOnRefresh from "./ScrollToTop";

const App = () => {
  useScrollToTopOnRefresh();

  useEffect(() => {
    AOS.init();

    const countUpOptions = {
      duration: 2,
    };

    const countElements = document.querySelectorAll(".count");

    countElements.forEach((element) => {
      const countUp = new CountUp(
        element,
        element.dataset.target,
        countUpOptions
      );
      element.addEventListener("aos:in", () => countUp.start());
    });

    const toggleHeight = window.outerHeight * 2;

    const handleScroll = () => {
      const mBackToTop = document.querySelector(".m-backtotop");

      if (window.scrollY > toggleHeight) {
        mBackToTop.classList.add("active");
      } else {
        mBackToTop.classList.remove("active");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const carouselOptions = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    navText: [
      '<i class="ri-arrow-left-line"></i>',
      '<i class="ri-arrow-right-line"></i>',
    ],
  };

  return (
    <>
      {/* <ScrollToTop/> */}
      <Rout />
    </>
  );
};

export default App;
