import React from "react";
import { Link } from "react-router-dom";

export default function EcommerceMobileApp() {
  return (
    <>
      <div
        className="inner-page"
        id="nodejs-design-bg"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h1>Ecommerce Mobile App</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="ri-home-4-line" />
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Ecommerce Mobile App
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section" data-aos="fade-up" data-aos-duration={1500}>
        <div className="bg-body-tertiary">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center">
              <div className="col-md-7">
                <h2 className="section-title">
                  Discover the Features of Our Ecommerce Mobile App{" "}
                </h2>
                <div className="d-flex align-items-center">
                  <i className="ri-pencil-ruler-line icon-color mr-3" />
                  <h5>Quick and Easy Product Search</h5>
                </div>
                <p className="">
                  Our Ecommerce Mobile App offers an intuitive product search
                  feature, making it easy for users to find what they need. From
                  electronics to fashion, effortlessly explore our extensive
                  catalog with smart search functionality.
                </p>
                <div className="text-center text-md-left ">
                  <Link to="/portfolio" className="btn1 btn_gap mb-2 mb-lg-0">
                  Explore Now
                  </Link>
                </div>
              </div>
              <div className="col-md-5">
                <div className="bg-dotted">
                  <img
                    src="assets/image/solutions/ecommerce-ss.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/ecommerce-checkout-app.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3 ">
                  <img
                    width={64}
                    height={64}
                    src="https://img.icons8.com/ios/64/ffffff/map.png"
                    className="mr-3"
                    alt="map"
                  />
                  Secure Checkout Process
                </h5>
                <p>
                  Experience a secure and seamless checkout process with our
                  Ecommerce Mobile App. Choose from various payment options,
                  ensuring your transactions are safe and hassle-free. Enjoy
                  peace of mind while making purchases.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/ecommerce-rating.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-calendar icon-color mr-3" />
                  <h5 className=""> Customer Reviews and Ratings</h5>
                </div>
                <p>
                  Gain insights from customer reviews and ratings featured in
                  our app. Make informed purchase decisions with feedback from
                  other customers. Contribute to the community by sharing your
                  own experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="section bg_color "
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/Personalized-Shopping-Experience.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="text-white">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/ios/50/ffffff/pass.png"
                    className="mr-3"
                    alt="pass"
                  />
                  Personalized Shopping Experience
                </h5>
                <p>
                  Tailor your shopping experience with personalized
                  recommendations. Our Ecommerce Mobile App understands your
                  preferences and provides suggestions based on your browsing
                  history, creating a delightful and unique shopping journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/real-time-tracking.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={50}
                    height={50}
                    src="https://img.icons8.com/sf-ultralight-filled/50/0d6efd/chat.png"
                    className="mr-3"
                    alt="chat"
                  />
                  Real-time Order Tracking
                </h5>
                <p>
                  Keep tabs on your orders with real-time tracking. Our
                  Ecommerce Mobile App lets you monitor the status and location
                  of your purchases from the moment they're dispatched until
                  they reach your doorstep. Stay informed every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section
        className="section  bg_color"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/coupons.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="">
                <div className="d-flex align-items-center">
                  <i className="fa fa-cogs icon-color-white mr-3" />
                  <h5 className="">Exclusive Promotions</h5>
                </div>
                <p>
                  Unlock special deals and discounts with our Ecommerce Mobile
                  App. Benefit from exclusive promotions, flash sales, and
                  limited-time offers. Save on your favorite products and enjoy
                  a rewarding shopping experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section " data-aos="fade-up" data-aos-duration={1500}>
        <div className="container py-3 py-lg-4">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-5">
              <div className="solutions-image">
                <img
                  src="assets/image/solutions/rating-reviews.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="">
                <h5 className="mb-3">
                  <img
                    width={66}
                    height={66}
                    src="https://img.icons8.com/external-3d-design-circle/66/external-Admin-Panel-design-and-development-3d-design-circle.png"
                    alt="external-Admin-Panel-design-and-development-3d-design-circle"
                  />{" "}
                  User Feedback and Ratings
                </h5>
                <p>
                  Encourage user feedback to enhance our services. Customers can
                  rate products, leave reviews, and share their experiences.
                  Your feedback helps us improve and ensures a community-driven
                  shopping platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section
        className="section  mb-10 py-3 py-lg-4"
        data-aos="fade-up"
        data-aos-duration={1500}
      >
        <div className="container">
          <h2 className="section-title-2 mb-10">
            Achieve Seamless Food Delivery with Our Advanced App
          </h2>
          <p>
            Elevate your food delivery service and achieve your business goals
            with our feature-rich and user-friendly Food Delivery App. We offer
            customization options to align the app with your brand and cater to
            your specific business needs.
          </p>
          {/* <div className="text-center">
            <button className="btn1  mb-2 mb-lg-0">Explore Feature</button>
            <button className="btn1">Try Demo</button>
          </div> */}
        </div>
      </section>
    </>
  );
}
