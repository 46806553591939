import React from "react";
import DesignBrandingTemplate from "./DesignBrandingTemplate";
import OurClient from "../../OurClient/OurClient";
import Faq from "../../Faq/Faq";
import { Link } from "react-router-dom";

const data = {
  faqHeader: "Frequently Asked Questions - Logo Design Services",
  caption:
    "Discover the details of our logo design services. If you have additional questions, feel free to reach out to our creative team.",
  faqContent: [
    {
      ques: "What Makes a Good Logo Design?",
      ans: "A good logo design is distinctive, memorable, versatile, and relevant to the brand it represents. It should be simple yet impactful, conveying the essence of the brand's identity and values. A well-designed logo resonates with the target audience and leaves a lasting impression, contributing to brand recognition and credibility.",
    },
    {
      ques: "Why is Logo Design Important for Brand Identity?",
      ans: "Logo design is crucial for brand identity as it serves as the visual representation of a brand. It is often the first point of contact between a brand and its audience, influencing perceptions and creating brand recall. A well-designed logo communicates the brand's personality, values, and differentiation, fostering brand loyalty and trust.",
    },
    {
      ques: "Do You Provide Custom Logo Design Solutions?",
      ans: "Absolutely! Our logo design services are tailored to meet the unique needs and preferences of each client. We collaborate closely with you to understand your brand, target audience, and design preferences, ensuring that the final logo reflects your brand identity and resonates with your audience. Whether you need a logo from scratch or a redesign of an existing one, we're here to bring your vision to life.",
    },
    {
      ques: "What File Formats Will I Receive for My Logo?",
      ans: "Upon completion of your logo design project, you will receive your logo in various file formats suitable for different use cases. These formats may include vector files such as SVG and EPS, which are scalable and suitable for printing, as well as raster formats such as PNG and JPEG for digital use. We ensure that you have the necessary files to effectively use your logo across various platforms and media.",
    },
  ],
};

export default function LogoDesign() {
  return (
    <>
      <section className="logo_design_sec1 bg_color1">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-8 col-sm-9">
              <div
                className="heading"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <h1>Logo Design </h1>
                <p>
                  Adret Software is a leading logo design company in India,
                  specialising in creating intuitive and user-friendly digital
                  experiences for businesses of all sizes. The company has a
                  team of experienced designers and developers who work closely
                  with clients to understand their requirements and deliver
                  customised logo design services in India that meet their
                  specific needs.
                </p>
                <div className="btn_sec ">
                  <Link to="/contact" className="btn1 btn_gap">
                    Quick Enquiry
                  </Link>
                  <Link to="/portfolio" className="btn3">
                    Portfolio
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-3">
              <img
                src="assets/image/logo-design.png"
                className="img-fluid"
                data-aos="zoom-in"
                data-aos-duration="2000"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_business">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Business Logo Design Services</h2>
                <p>
                  Think about some of the iconic brands you remember – the
                  moment you see their logo, the entire brand or company name as
                  well as their history comes into mind. This is how powerful a
                  logo can be. Our experienced logo designers know how to make
                  the perfect mix of symbols, calligraphy, design art and other
                  aesthetic graphical elements to create such an effective logo.
                  The logo evokes emotions and sensations related to your brand
                  and helps build recall to ultimately grow your customer base.
                </p>
                <p>
                  With over 15 years of experience in the field and some of the
                  best creative professionals within the industry, we can
                  deliver high quality and unique business logo design services
                  for all your needs. As a trusted logo design company, we have
                  served over thousands of clients worldwide till date.
                </p>
                <div className="btn_sec">
                  <Link to="/contact" className="btn1 btn_gap">
                    Request a Quote
                  </Link>
                  <Link to="/pricing" className="btn3">
                    Our Packages
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="logo_design_best">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Logo Design Company India - Best Logo Designers</h2>
                <p>
                  As a premium logo design company in India, our expert logo
                  designers have made unique logos for small businesses as well
                  as large enterprises worldwide. Our logo designs span multiple
                  industries such as, real estate, education, financial
                  institutions, food, hospitality, information technology,
                  fashion and more.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <iframe
                width="100%"
                src="assets/image/adret-video.mp4"
                title="Adret intro video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                data-aos="zoom-out"
                data-aos-duration="2000"
              ></iframe>
            </div>
          </div>
        </div>
      </section> */}
      <section className="logo_design_project p-0">
        <div className="row g-0 ">
          <div className="col-lg-5">
            <div
              className="content_img"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                src="assets/image/logo-design-img1.jpg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="heading" data-aos="fade-left" data-aos-duration="2000">
              <h2>What can we do for you?</h2>
              <p>We would be happy to discuss the project with you in person</p>
              <h4>
                Call To Us : <Link to="tel:+18885054030">+1 888 505 4030</Link>   
              </h4>
              <div className="btn_sec">
                <Link to="/contact" className="btn2">
                  Drop in a message
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logo_design_sec">
        <div className="container">
          <div className="heading" data-aos="fade-up" data-aos-duration="2000">
            <h2>
              Why Choose Adret Software <br />
              Professional Logo Design Company India
            </h2>
            <p>
              A great logo design can help your brand to gain high visibility
              and user traction. At Adret Software, the professional logo design
              company in India, we design attractive logos that enhance your
              brand proposition at cost effective rates. Our expert logo
              designers weave magic using aesthetic graphical elements,
              attractive colour palette, intricate calligraphy, and fabulous
              design art to make your logo grab eyeballs.
            </p>
          </div>
          <div className="row g-4">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon1.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Detailed Brand Analysis</h5>
                      <p>
                        Logo design is undertaken after a thorough analysis of
                        your brand.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon2.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Affordable Packages</h5>
                      <p>
                        We have many packages to suit various business logo
                        design needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon3.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Experienced Designers</h5>
                      <p>
                        Our designers have been delivering high end custom logo
                        designs since 2015.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="content_why"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <div className="left_icon">
                      <img
                        src="assets/image/logo-design-icon4.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="content">
                      <h5>Multiple Design Concepts</h5>
                      <p>
                        We provide varied and multiple design concepts for our
                        clients to choose from.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_sec" data-aos="fade-up" data-aos-duration="3000">
                <Link to="/pricing" className="btn1">
                  Logo Design Packages
                </Link>
              </div>
            </div>
            {/* <div className="col-lg-4">
              <img
                src="assets/image/logo-design-img2.png"
                className="img-fluid  rounded"
                alt=""
                data-aos="zoom-out"
                data-aos-duration="2000"
              />
            </div> */}
          </div>
        </div>
      </section>

      {/* <section className="portfolio_logo bg_color1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center row_gap">
              <div className="heading" data-aos="fade-up" data-aos-duration="2000">
                <h2>Our Logo Design Portfolio</h2>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-6">
              <div
                className="logo_con_port"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img
                  src="assets/image/logo-design-protfolio-img1.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 row-below text-center">
              <div className="btn_sec" data-aos="zoom-in" data-aos-duration="2000">
                <Link to="" className="btn1">
                  See Full Portfolio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <OurClient />
      {/* <section className="faq_sec bg_color1" id="faq_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading" data-aos="zoom-in" data-aos-duration="2000">
                <h2>Logo Design Services – Frequently Asked Questions</h2>
                <p>
                  Before engaging our logo design services, clear your doubts.
                  For more assistance, feel free to ask our experts.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="accordion"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <div className="accordion__item">
                  <h2 className="accordion__title">
                    Is it possible to pitch in ideas for a logo design?
                  </h2>
                  <div className="accordion__body">
                    <p>
                      We welcome your creative thoughts. Share your ideas with
                      us and our talented graphic designers can translate your
                      vision and create logo designs based on your concept. We
                      can also provide alternative suggestions – the more the
                      merrier!
                    </p>
                  </div>
                </div>
                <div className="accordion__item">
                  <h2 className="accordion__title ">
                    What is the price for a custom logo design?
                  </h2>
                  <div className="accordion__body">
                    <p>
                      Logo design cost in India depends on multiple factors.
                      These include the number of design concepts, number of
                      revisions, etc. You can find a starting range here. For
                      more information, feel free to give us a call.
                    </p>
                  </div>
                </div>
                <div className="accordion__item">
                  <h2 className="accordion__title ">
                    Is it possible to create logo design for companies,
                    products, or names?
                  </h2>
                  <div className="accordion__body">
                    <p>
                      Yes. We can create any type of graphic identity that suits
                      your requirement.
                    </p>
                  </div>
                </div>
                <div className="accordion__item">
                  <h2 className="accordion__title ">
                    Is it possible to make a change to an existing logo?
                  </h2>
                  <div className="accordion__body">
                    <p>
                      If you want to modify your existing logo, we can arrange
                      for the same. We also provide multiple revisions and a
                      variety of concepts for our own logo designs. Your
                      feedback is valuable to us and we can bring your vision
                      into reality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Faq data={data} />
    </>
  );
}
