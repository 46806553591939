import React, { useState } from "react";
import { AddContact } from "../../apis/ApiIntegrate";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { notificationConfig } from "../Notification/config";
import axios from "axios";
import OurClient from "../OurClient/OurClient";
import { Link } from "react-router-dom/dist";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    business: "",
    other: "",
    category: "",
    country: "",
    message: "",
  });

  const [othContainer, setOthContainer] = useState(false);
  const handleChangeContainer = (e) => {
    const { name, value } = e.target;

    if (value == "Others") {
      setOthContainer(true);
    } else {
      setOthContainer(false);
      setData((prevData) => ({ ...prevData, ["other"]: "" }));
    }

    setData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    business: "",
    other: "",
    category: "",
    country: "",
    message: "",
  });
  const handleChangeOther = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = "Name is required";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email format";
    }

    // Validate mobile
    if (!data.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    }

    // Validate category
    if (!data.category.trim()) {
      errors.category = "Category is required";
    }
    if (data.category == "Others") {
      if (!data.other.trim()) {
        errors.other = "Other is required";
      }
    }
    // Validate message
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const isValid = validateForm();
    if (isValid) {
      try {
        const response = await axios.post(
          "https://project.adretsoftware.in/adret_apis/add-contact.php",
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setIsLoading(false);
        if (response.data === 200) {
          setOthContainer(false);
          setData({
            name: "",
            email: "",
            mobile: "",
            business: "",
            other: "",
            category: "",
            country: "",
            message: "",
          });

          setValidationErrors({});
          toast.success("Request Submitted");
        } else {
          toast(response.data, notificationConfig.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="inner-page" id="contact-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <h1>Contact Us</h1>
                <div aria-label="breadcrumb">
                  <ol class="breadcrumb justify-content-start">
                    {/* <li class="breadcrumb-item">
                      <b>
                        Unit No.206, Dn-10, Merlin Matrix, DN Block, Sector V,
                        Bidhannagar, Kolkata, West Bengal 700091
                      </b>
                    </li>
                    <li class="breadcrumb-item">
                      <b>47 W 13th St, New York, NY 10011, USA</b>
                    </li>
                    <li class="breadcrumb-item active">
                      <b> Customer Helpline : +1 888 505 4030</b>
                    </li> */}
                    <li style={{ textAlign: "left", paddingBottom: 10 }}>
                      Unit No.206, Dn-10, Merlin Matrix, DN Block, Sector V,
                      Bidhannagar, Kolkata, West Bengal 700091
                    </li>
                    <li style={{ textAlign: "left", paddingBottom: 10 }}>
                      47 W 13th St, New York, NY 10011, USA
                    </li>
                    <li style={{ textAlign: "left", paddingBottom: 10 }}>
                      Customer Helpline : +1 888 505 4030
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact">
        <div className="container">
          <div className="row">
            <div
              className="col-12 mb-4 mb-md-0 heading"
              data-aos="fade-down"
              data-aos-duration={2000}
            >
              <h2>Contact Us</h2>
              <p>
                Get in touch with us! Our team is here to assist you. Reach out
                for inquiries, collaborations, or any assistance you need
              </p>
            </div>
          </div>
          <div className="row g-3 justify-content-start">
            <div className="col-lg-3 col-md-4">
              <div
                className="contact-top"
                data-aos="fade-up"
                data-aos-duration={2500}
              >
                <div
                  className="contact-icon"
                  data-aos="zoom-in"
                  data-aos-duration={3000}
                >
                  <img src="assets/image/phone.gif" alt="" />
                </div>
                <div className="contact-info-top">
                  <h4>Call Us</h4>
                  <p className="mb-0">
                    <Link to="tel:+18885054030">+1 888 505 4030</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-below">
            <div className="col-md-12 mx-auto order-1 order-md-2 mb-4 mb-md-0">
              <div
                className="contact-form"
                data-aos="fade-up"
                data-aos-duration={2000}
              >
                <form action="" method="post">
                  <h4>GET IN TOUCH</h4>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="contact_box">
                        <span className="contact_sticon">
                          <i className="fa fa-user-o" aria-hidden="true" />
                        </span>
                        <input
                          type="text"
                          name="name"
                          value={data.name}
                          id=""
                          placeholder="Enter your Name"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                      <span className="error">
                        {validationErrors.name && (
                          <i className="fa-solid fa-triangle-exclamation"></i>
                        )}{" "}
                        {validationErrors.name}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <div className="contact_box">
                        <span className="contact_sticon">
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                        </span>
                        <input
                          type="email"
                          name="email"
                          value={data.email}
                          id=""
                          placeholder="Enter your E-mail id"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                      <span className="error">
                        {validationErrors.email && (
                          <i className="fa-solid fa-triangle-exclamation"></i>
                        )}{" "}
                        {validationErrors.email}
                      </span>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="contact_box">
                        <span className="contact_sticon">
                          <i className="fa fa-mobile" aria-hidden="true" />
                        </span>
                        <input
                          type="text"
                          name="mobile"
                          value={data.mobile}
                          id=""
                          placeholder="Enter your Phone Number"
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                      <span className="error">
                        {validationErrors.mobile && (
                          <i className="fa-solid fa-triangle-exclamation"></i>
                        )}{" "}
                        {validationErrors.mobile}
                      </span>
                    </div>
                    <div className="col-md-6 col-sm-12 portfolio-contact">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        name="category"
                        onChange={(e) => handleChangeContainer(e)}
                        value={data.category}
                      >
                        <option value="">--Select Category--</option>
                        <option value="SOFTWARE">SOFTWARE</option>
                        <option value="WEBSITE DESIGN">WEBSITE DESIGN</option>
                        <option value="WEBSITE DEVELOPMENT">
                          WEBSITE DEVELOPMENT
                        </option>
                        <option value="GRAPHIC DESIGN">GRAPHIC DESIGN</option>
                        <option value="UI/UX DESIGNING">UI/UX DESIGNING</option>
                        <option value="ECOMMERCE DEVELOPMENT">
                          ECOMMERCE DEVELOPMENT
                        </option>
                        <option value="MOBILE APP DEVELOPMENT">
                          MOBILE APP DEVELOPMENT
                        </option>
                        <option value="GAME DEVELOPMENT">
                          GAME DEVELOPMENT
                        </option>
                        <option value="DIGITAL MARKETING">
                          DIGITAL MARKETING
                        </option>
                        <option value="Others">Others</option>
                      </select>
                      <span className="portfolio-contact-error">
                        {validationErrors.category && (
                          <i className="fa-solid fa-triangle-exclamation"></i>
                        )}{" "}
                        {validationErrors.category}
                      </span>
                    </div>
                    {othContainer && (
                      <div className="col-12 portfolio-contact">
                        <input
                          type="text"
                          name="other"
                          id=""
                          value={data.other}
                          placeholder="Enter other details"
                          onChange={(e) => handleChangeOther(e)}
                          style={{ border: "1px solid #e2e2e2" }}
                        />
                        <span className="portfolio-contact-error">
                          {validationErrors.other && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}{" "}
                          {validationErrors.other}
                        </span>
                      </div>
                    )}
                    <div className="col-md-6 col-sm-12 portfolio-contact">
                      <select
                        id="country"
                        name="country"
                        class="form-control"
                        onChange={(e) => handleChangeContainer(e)}
                        value={data.country}
                      >
                        <option value="--Select Country--">
                          --Select Country--
                        </option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      <span className="portfolio-contact-error">
                        {validationErrors.category && (
                          <i className="fa-solid fa-triangle-exclamation"></i>
                        )}{" "}
                        {validationErrors.category}
                      </span>
                    </div>
                    <div class="col-md-6 col-sm-12 portfolio-contact">
                      <input
                        type="text"
                        name="business"
                        id=""
                        value={data.business}
                        placeholder="Enter your Business Name"
                        onChange={(e) => handleChange(e)}
                        className="mb-1"
                        style={{ border: "1px solid #e2e2e2" }}
                      />
                      <span className="portfolio-contact-error">
                        {validationErrors.business && (
                          <i class="fa-solid fa-triangle-exclamation"></i>
                        )}
                        {validationErrors.business}
                      </span>
                    </div>
                    <div className="col-12">
                      <textarea
                        name="message"
                        value={data.message}
                        id=""
                        cols={30}
                        placeholder="Message"
                        rows={5}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <span className="error">
                      {validationErrors.message && (
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      )}{" "}
                      {validationErrors.message}
                    </span>
                    <div className="col-12">
                      <button onClick={handleSubmit}>
                        {" "}
                        {isLoading && (
                          <span
                            className="spinner-grow spinner-grow-sm me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}{" "}
                        Submit now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>

      <OurClient />

      {/* <Team /> */}
    </>
  );
};

export default Contact;
