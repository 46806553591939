import React from "react";
import Breadcumb from "../../Breadcumb";
import WebSolTemplate from "./WebSolTemplate";
import { Helmet } from "react-helmet";

export default function WebsiteDesign() {
  const data = {
    breadcumb: { name: "Website Design Service", img: "" },
    
    aboutUpper: {
      content:
        "A website is a collection of related web pages, multimedia content, and other digital assets that are typically identified with a common domain name and published on at least one web server. Websites are accessible over the Internet or a private network, allowing users to navigate through the pages using web browsers. A website can serve various purposes, including providing information, entertainment, e-commerce, social interaction, or a combination of these. Websites can range from simple, static pages with text and images to complex, dynamic sites with interactive features and databases.Websites are built using various technologies, including HTML (Hypertext Markup Language), CSS (Cascading Style Sheets), JavaScript, and other programming languages. Content management systems (CMS) are often used to facilitate the creation and management of website content, allowing individuals or organizations to update and maintain their sites without extensive technical knowledge.",
      img: "https://www.webguru-india.com/assets/images/website-design-services-design-plate.webp",
    },


    aboutLower: {
      heading: "End-to-End Web Development Solutions",
      sbuheading:
        "Craft Your Business Website with a Leading Web Development Agency",
      content:
        "We, as the premier web development agency in India, specialize in crafting SEO-friendly, mobile-responsive business websites, finely tuned to your specifications. Our comprehensive services cover design, development, and ongoing maintenance for an impactful online business presence. Choose from a range of tailored web development services to suit your specific needs. If you're uncertain about the best fit for your requirements, reach out to us. We'll guide you through technical details, assess your project scope, and provide accurate cost estimates for a seamless decision-making process",
    },


    cat_section: [
      {
        icon: "ri-pages-line icon-color",
        name: "Small Business Website",
        content:
          "When launching a startup, a website might appear as an unnecessary expense. However, in today's digital era, it's a pivotal business component. Intense market competition necessitates a striking digital presence from the start. Our process involves in-depth research of your niche, understanding challenges, and spotting opportunities. This guides our strategic plan to craft an appealing website. Whether you need a single-page application (SPA) or a multi-page website, we tailor our approach to suit your requirements and business objectives.",
      },
      {
        icon: "ri-line-chart-line icon-color",
        name: "Web Application Development",
        content:
          "An interactive web application readily amplifies user engagement. Our user-centred web apps are feature-rich, intuitive, and perform exceedingly well. We craft your web destination with a clear view of the business goal and ensure that it supports the user-journey. The responsive web applications produce a seamless omnichannel experience on cross-devices. Our web apps have rich UI/UX, normalized database, and robust frameworks to offer an optimum performance.",
      },
      {
        icon:"ri-shopping-cart-line icon-color",
        name: "eCommerce Website Design",
        content:
          "Converting casual online buyers into loyal customers – that’s what you want as an online retailer. Rated as the top web designers in India, we can build an optimised online store using popular platforms and frameworks. You can choose from various CMS options (Shopify, BigCommerce, Magento, OpenCart, WordPress) or extensive custom programming for shopping cart development as per your requirements. We also provide services for developing impressive Marketplace, both B2B & B2C Structures.",
      },
      {
        icon: "ri-pencil-ruler-fill icon-color",
        name: "Corporate Website Design",
        content:
          "High-end professionalism, impressive User Interface, easy accessibility - a corporate website has to reflect everything. Don’t get overwhelmed as we are here to assist. As a trusted web design agency, we craft innovative and secure websites for large enterprises all around the world. Adept with technical skill, an extensive experience, consistent communication with the client, and attention to every detail, we ensure a quality service within a quick turnaround time.",
      },

    ],
    cat_section_lwer: { content: "As a top-tier website design company, we prioritize delivering high-quality web design services at competitive rates, aimed at enhancing your online business success. Whether you need an informative site for a small business, a dynamic eCommerce platform, or a custom enterprise-level web application, our skilled developers excel in fortifying and building your online presence. Harness our premium web development services to expedite lead conversions and drive growth for your business." },


    faqSection: [
      {
        title: "How do I get started with your website design services?",
        content: "To get started with our website design services, simply contact us to schedule a consultation. We'll discuss your project requirements and provide you with a free quote. Once you're ready to proceed, we'll begin working on your website design.",
      },
      {
        title: "How do your website development services benefit my business?",
        content: "Our web development services can help your company in a variety of ways. A well-designed and user-engaging website may boost your online visibility and credibility. It can also help to automate and simplify your existing business processes. Ultimately, the high-quality website that we create for you will help you convert more visitors into customers and grow your business.",
      },
      {
        title: "Will it be possible to hire website developers on demand for custom requirements?",
        content: "Yes, you may hire our developers on demand for design, development, and maintenance jobs. We offer flexible engagement models depending on the complexity and duration of the project. We maintain complete confidentiality, and deliver the source code as and when needed. You may hire our developers with varied fields of expertise, like WordPress developers, Angular developers, Laravel developers or others.",
      },
    ],
  };
  return (
    <>
    <Helmet>
        <title>Website Design development company in kolkata - Adret Software</title>
        <meta name="google-site-verification" content="googlebff0f5cdd84bde27.html" />
        
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NWG52HBC');`}
        </script>
        {/* End Google Tag Manager */}

        {/* Google Tag Manager (noscript) */}
        <noscript>
         {` <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NWG52HBC"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>`}
        </noscript>
        {/* End Google Tag Manager (noscript) */}

        <meta name="best website design development company in kolkata" />
        <meta name="keywords" content="Web Design company in Kolkata, Website Design Kolkata, Web Design Company in Kolkata, Web Development Company in Kolkata, Web Development Company Kolkata" />
        <meta name="description" content="Adret Software is a top website design company in Kolkata, India. We offer Web Solutions, Design & branding, Ecommerce Development, App Development, Marketing & Lead Generation services. Contact us for more info." />
        <link rel="canonical" href="https://adretsoftware.in/website-design/" />
        
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Services - Adret Software : Best Website design development company in Kolkata" />
        <meta property="og:description" content="Adret Software is a top website design development company in Kolkata, India. We offer Web Solutions, Design & branding, Ecommerce Development, App Development, Marketing & Lead Generation services. Contact us for more info." />
        <meta property="og:url" content="https://adretsoftware.in/website-design/" />
      </Helmet>
      <WebSolTemplate data={data} />
    </>
  );
}
