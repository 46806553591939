import React, { useState } from "react";
import Breadcumb from "../../Breadcumb";
import WebSolTemplate from "./WebSolTemplate";
import OurClient from "../../OurClient/OurClient";
import ToolsTechnology from "../../ToolsTechnology/ToolsTechnology";
import { Link } from "react-router-dom";

export default function WebAppDevelopement() {
  const faqSection = [
    {
      title: "How do I get started with your website design services?",
      content:
        "To get started with our website design services, simply contact us to schedule a consultation. We'll discuss your project requirements and provide you with a free quote. Once you're ready to proceed, we'll begin working on your website design.",
    },
    {
      title: "How do your website development services benefit my business?",
      content:
        "Our web development services can help your company in a variety of ways. A well-designed and user-engaging website may boost your online visibility and credibility. It can also help to automate and simplify your existing business processes. Ultimately, the high-quality website that we create for you will help you convert more visitors into customers and grow your business.",
    },
    {
      title:
        "Will it be possible to hire website developers on demand for custom requirements?",
      content:
        "Yes, you may hire our developers on demand for design, development, and maintenance jobs. We offer flexible engagement models depending on the complexity and duration of the project. We maintain complete confidentiality, and deliver the source code as and when needed. You may hire our developers with varied fields of expertise, like WordPress developers, Angular developers, Laravel developers or others.",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <>
      <>
        <div
          className="inner-page"
          id="website-design-bg"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="inner_content"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <h1>Web Application Development</h1>
                  <div aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="ri-home-4-line"></i>Home
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Web Application Development
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="website-banner section"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <div className="row align-items-center g-4">
              <div className="col-md-6">
                <div className="heading">
                  <h3>Web Application Development</h3>
                  <p>
                    Experience cutting-edge web application development tailored
                    to your business needs. Our expert developers create
                    powerful, scalable, and user-friendly web applications that
                    drive innovation and enhance your digital presence. From
                    conceptualization to deployment, we ensure seamless
                    functionality and optimal user experience. Trust us to
                    transform your ideas into feature-rich web applications that
                    propel your business forward.
                  </p>
                  <div className="btn_sec">
                    <Link to="/contact" className="btn1 btn_gap">
                      Quick Enquiry
                    </Link>
                    <Link to="/portfolio" className="btn3">
                      Explore Web App
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <img
                  src="https://www.webguru-india.com/assets/images/website-design-services-design-plate.webp"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="section bg_color1"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="container">
            <h2 className="section-title">
              Comprehensive Web Application Development Solutions
            </h2>
            <p className="section-subtitle">
              Build Your Robust Web Application with a Leading Development
              Agency
            </p>

            <p>
              As a top-tier web application development agency, we specialize in
              crafting custom, secure, and scalable web applications. Our
              end-to-end services cover everything from ideation to deployment,
              ensuring a seamless development process. Choose from a range of
              tailored web application development services to suit your
              specific needs. If you're unsure about the best approach for your
              project, our team is here to guide you through the technical
              details and provide accurate cost estimates for an informed
              decision-making process.
            </p>
          </div>

          <div className="details-website">
            <div className="container py-5">
              <div className="row">
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="py-5">
                    <div className="d-flex align-items-center">
                      <i className="ri-pages-line icon-color"></i>
                      <p className="subtitle">Custom Web Applications</p>
                    </div>
                    <p>
                      Elevate your business with custom web applications
                      tailored to your specific needs. Our development process
                      involves in-depth analysis and strategic planning,
                      ensuring your web application aligns with your business
                      objectives. Whether you need a single-page application
                      (SPA) or a complex multi-page web application, we
                      customize our approach to meet your requirements
                      effectively.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="py-5">
                    <div className="d-flex align-items-center">
                      <i className="ri-line-chart-line icon-color"></i>
                      <p className="subtitle">Interactive User-Centric Design</p>
                    </div>
                    <p>
                      Enhance user engagement with our feature-rich, intuitive
                      web applications. We prioritize the user journey, ensuring
                      seamless functionality and optimal performance across
                      devices. Our responsive web applications provide a unified
                      experience, contributing to a seamless omnichannel
                      presence. Benefit from rich UI/UX, a normalized database,
                      and robust frameworks that ensure optimum performance.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="py-5">
                    <div className="d-flex align-items-center">
                      <i className="ri-shopping-cart-line icon-color"></i>
                      <p className="subtitle">eCommerce Solutions</p>
                    </div>
                    <p>
                      Transform online visitors into loyal customers with our
                      top-notch eCommerce solutions. As experienced web
                      designers, we build optimized online stores using popular
                      platforms and frameworks. Choose from various CMS options
                      (Shopify, BigCommerce, Magento, OpenCart, WordPress) or
                      opt for extensive custom programming to develop a shopping
                      cart tailored to your requirements. Explore our services
                      for creating impressive B2B and B2C marketplace
                      structures.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div className="py-5">
                    <div className="d-flex align-items-center">
                      <i className="ri-pencil-ruler-fill icon-color"></i>
                      <p className="subtitle">Corporate Web Application Design</p>
                    </div>
                    <p>
                      Achieve high-end professionalism and impressive user
                      interface with our corporate web application designs. We
                      assist large enterprises worldwide by crafting innovative
                      and secure websites. Our team, adept with technical skills
                      and extensive experience, ensures consistent communication
                      with clients and meticulous attention to detail.
                      Experience quality service with quick turnaround times.
                    </p>
                  </div>
                </div>
              </div>
              <p className="py-3">
                As a top-tier web application development company, we prioritize
                delivering high-quality services at competitive rates. Whether
                you need a custom web application, an interactive user-centric
                design, eCommerce solutions, or corporate web application
                design, our skilled developers excel in fortifying and building
                your online presence. Leverage our premium web development
                services to expedite lead conversions and drive growth for your
                business.
              </p>
              <div className="btn_sec">
                <Link to="/contact" className="btn1 btn_gap">
                  Discuss Your Project
                </Link>
                <Link to="/portfolio" className="btn3">
                  Our Web Apps
                </Link>
              </div>
            </div>
          </div>
        </section>
        <OurClient />
        <ToolsTechnology />

        {/* <section className="wes d-lg-block d-none bg_color">
          <div className="container">
            <h2 className="section-title-2 text-center">
              Our Full-Stack Web Development Services
            </h2>
            <p className="section-subtitle text-center">
              With a strategic approach and creative mind, our full-stack
              website developers can craft industry-standard web applications
              that help in unleashing the full potential of your business.
              Engage our full-stack web development services to get future-ready
              web apps.
            </p>
            <ul
              className="nav nav-tabs nav-justified mb-3 "
              id="ex1"
              role="tablist"
            >
              <li className="nav-item mr-5" role="presentation">
                <a
                  className="nav-link active"
                  id="ex3-tab-1"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-1"
                  role="tab"
                  aria-controls="ex3-tabs-1"
                  aria-selected="true"
                >
                  Frontend Development
                </a>
              </li>
              <li className="nav-item mr-5" role="presentation">
                <a
                  className="nav-link"
                  id="ex3-tab-2"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-2"
                  role="tab"
                  aria-controls="ex3-tabs-2"
                  aria-selected="false"
                >
                  {" "}
                  Backend Development
                </a>
              </li>
              <li className="nav-item mr-5" role="presentation">
                <a
                  className="nav-link"
                  id="ex3-tab-3"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-3"
                  role="tab"
                  aria-controls="ex3-tabs-3"
                  aria-selected="false"
                >
                  API & Integration
                </a>
              </li>
              <li className="nav-item mr-5" role="presentation">
                <a
                  className="nav-link"
                  id="ex3-tab-4"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-4"
                  role="tab"
                  aria-controls="ex3-tabs-4"
                  aria-selected="false"
                >
                  Database
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="ex3-tab-5"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-5"
                  role="tab"
                  aria-controls="ex3-tabs-5"
                  aria-selected="false"
                >
                  Version Control
                </a>
              </li>
            </ul>

            <div className="tab-content" id="ex2-content">
              <div
                className="tab-pane fade show active"
                id="ex3-tabs-1"
                role="tabpanel"
                aria-labelledby="ex3-tab-1"
              >
                <div className="d-flex align-items-center">
                  <i className="ri-pencil-ruler-2-line icon-color icon-style"></i>
                  <h4 className="mb-0 ml-2">Frontend Development</h4>
                </div>
                <p>
                  At Adret, we take pride in having a team of skilled engineers.
                  Our developers are proficient in the latest frontend
                  technologies such as Angular, Vue, React, Materialize, and
                  SCSS. Our high-end web architecture development, UI
                  enhancement and performance tuning are appreciated by a global
                  clientele across business verticals. Get your work done with
                  us.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="ex3-tabs-2"
                role="tabpanel"
                aria-labelledby="ex3-tab-2"
              >
                <div className="d-flex align-items-center">
                  <i className="ri-terminal-window-line icon-color icon-style"></i>
                  <h4 className="mb-0 ml-2">Backend Development</h4>
                </div>
                <p>
                  Our backend developers possess years of experience to support
                  your business growth with a robust and scalable web solution.
                  We are up-to-date with the industry trends and top-notch
                  technologies like Node.js, Symfony, Laravel, CakePHP,
                  CodeIgniter etc. We work with dexterity and ensure to generate
                  an end result that is built to perfection.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="ex3-tabs-3"
                role="tabpanel"
                aria-labelledby="ex3-tab-3"
              >
                <div className="d-flex align-items-center">
                  <img
                    src="assets/image/api.png"
                    className="icon-color icon-style p-2"
                    alt=""
                  />
                  <h4 className="mb-0 ml-2">API Development & Integration</h4>
                </div>
                <p>
                  An API is a computing interface that underlines the
                  interactions between multiple software intermediaries. RESTful
                  API development is a core focus at Adret Software Services. We
                  craft secure custom REST framework as a robust and flexible
                  toolkit for creating custom web APIs. We maintain a
                  three-level protocol to ensure REST API security -
                  authentication / tokenization, encryption and origin
                  validation.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="ex3-tabs-4"
                role="tabpanel"
                aria-labelledby="ex3-tab-4"
              >
                <div className="d-flex align-items-center">
                  <img
                    src="assets/image/db.png"
                    className="icon-color icon-style p-3"
                    alt=""
                  />
                  <h4 className="mb-0 ml-2">Database</h4>
                </div>
                <p>
                  Our programmers are efficient in working with a variety of
                  database to ensure that your application’s data is completely
                  secure and easily accessible whenever needed. Our expertise
                  extends to various relational and non-relational database
                  management systems including PostgreSQL, MySQL, and NoSQL
                  database (MongoDB, Apache Cassandra). We specialize on using
                  Database Middleware that supports interoperability between
                  various software components, facilitates distributed
                  transaction execution, helps to improve database services like
                  fault tolerance and performance scaling, and also adapts to
                  workloads by using adaptive queuing middleware.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="ex3-tabs-5"
                role="tabpanel"
                aria-labelledby="ex3-tab-5"
              >
                <div className="d-flex align-items-center">
                  <img
                    src="assets/image/version-control.png"
                    className="icon-color icon-style img-fluid p-3"
                    alt=""
                  />
                  <h4 className="mb-0 ml-2">Version Control</h4>
                </div>
                <p>
                  Version control system helps the development team to manage
                  any change to the source code over time. It keeps track of all
                  the modifications done to a code in a specific database. In
                  case of any unintended error, the developers can turn to the
                  previous version and fix the issues. Since an entire
                  development team works on a project, it’s better to organize
                  the codes for the apps in the file tree and keep a track of
                  the changes. This accelerates the process of a glitch-free app
                  development. We prefer Bitbucket and GitHub to support our
                  source control needs and maintain a smooth workflow.
                </p>
              </div>
            </div>
          </div>
        </section> */}

        {/* faq starts */}
        <section className="faq_sec bg_color1" id="faq_sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="heading"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  <h2>
                    Web Application Development – Frequently Asked Questions
                  </h2>
                  <p>
                    Explore answers to common queries about our web application
                    development services. If you have additional questions, feel
                    free to get in touch with our experts.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="accordion"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  <div className="accordion__item">
                    <h2 className="accordion__title">
                      What is the difference between a website and a web
                      application?
                    </h2>
                    <div className="accordion__body">
                      <p>
                        Websites primarily provide static content and
                        information, while web applications are dynamic,
                        allowing users to interact, perform tasks, and receive
                        personalized experiences. Web applications often involve
                        complex functionality, data processing, and user input.
                      </p>
                    </div>
                  </div>
                  <div className="accordion__item">
                    <h2 className="accordion__title ">
                      How long does it take to develop a custom web application?
                    </h2>
                    <div className="accordion__body">
                      <p>
                        The development time for a web application varies based
                        on factors such as project complexity, features, and
                        client requirements. Simple web applications might take
                        a few weeks, while more complex ones can take several
                        months. A thorough project analysis can provide a more
                        accurate estimate.
                      </p>
                    </div>
                  </div>
                  <div className="accordion__item">
                    <h2 className="accordion__title ">
                      Which technologies are commonly used in web application
                      development?
                    </h2>
                    <div className="accordion__body">
                      <p>
                        Web application development involves various
                        technologies. Front-end development often utilizes HTML,
                        CSS, and JavaScript frameworks like React or Angular.
                        For back-end development, languages like Node.js, Python
                        (Django), Ruby (Ruby on Rails), and PHP are common.
                        Databases such as MySQL, PostgreSQL, and MongoDB are
                        also widely used.
                      </p>
                    </div>
                  </div>
                  <div className="accordion__item">
                    <h2 className="accordion__title ">
                      How do you ensure the security of a web application?
                    </h2>
                    <div className="accordion__body">
                      <p>
                        Security is a top priority in web application
                        development. Measures include data encryption, secure
                        coding practices, input validation, and regular security
                        audits. Implementing user authentication and
                        authorization, using HTTPS, and staying updated on
                        security best practices are crucial for safeguarding web
                        applications
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}
