import React from "react";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import Faq from "../Faq/Faq";

const data = {
  faqHeader: "Frequently Asked Questions - Hire Laravel Developer",
  caption:
    "Explore the details of hiring Laravel developers from our skilled team. Should you have additional inquiries or wish to discuss your project specifics, feel free to reach out to us.",
  faqContent: [
    {
      ques: "What skills should I look for when hiring a Laravel developer?",
      ans: "When hiring a Laravel developer, it's important to look for expertise in PHP programming language, proficiency in Laravel framework, including knowledge of MVC architecture, Eloquent ORM, Blade templating engine, and artisan command-line interface. Additionally, familiarity with database systems like MySQL or PostgreSQL, understanding of RESTful API development, and experience with version control systems such as Git are valuable.",
    },
    {
      ques: "Do you provide flexible engagement models for Laravel developers?",
      ans: "Yes, we offer flexible engagement models tailored to your project requirements. Whether you need a dedicated Laravel developer for a long-term project or prefer hourly-based arrangements for short-term tasks, we can accommodate your needs.",
    },
    {
      ques: "Can your Laravel developers work remotely?",
      ans: "Absolutely! Our Laravel developers are experienced in remote collaboration and can effectively work with your team regardless of geographical location. We utilize communication tools and project management systems to ensure seamless coordination and timely project delivery.",
    },
    {
      ques: "How do you ensure the quality of Laravel development?",
      ans: "Quality assurance is a priority in our Laravel development process. Our developers follow coding standards, conduct thorough testing using PHPUnit and other testing frameworks, adhere to Laravel best practices for security and performance optimization, and strive to deliver high-quality, scalable web applications that meet your business requirements.",
    },
  ],
};

export default function HireLaravelDev() {
  return (
    <>
      <div
        className="inner-page"
        id="hire-us-bg"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>Hire Laravel Developer</h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="index.html">
                        <i className="ri-home-4-line"></i>Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Hire Laravel Developer
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading row_gaping">
            <h2 className="section-title-2 text-center">
              Engage Laravel Experts at the Best rates
            </h2>
          </div>
          <div className="center-row row row-below g-2 row-cols-lg-6 row-cols-sm-3 row-cols-2">
            <div className="col-md text-center">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-1.png" alt="" />
                <h5>One Week Free Trial</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-2.png" alt="" />
                <h5>Minimum 3+ Years of Experience</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-3.png" alt="" />
                <h5>Non-Disclosure Agreement</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-4.png" alt="" />

                <h5>Flexible Hiring Models</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-5.png" alt="" />

                <h5>Competitive Rate</h5>
              </div>
            </div>
            <div className="col-md">
              <div className="hire_content">
                <img src="assets/image/hire-angular-icon-6.png" alt="" />
                <h5>Source Code Delivery</h5>
              </div>
            </div>
          </div>
          <div className="btn_sec text-center row-below">
            <Link to="/contact" className="btn2 btn_gap">
              Get Started
            </Link>
            <Link to="/clients" className="btn2">
              Our Clients
            </Link>
          </div>
        </div>
      </section>

      <section
        className="section hire_content_anguler"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="small-hire ">
          <div className="row">
            <div className="col-lg-6 ml-auto">
              <div className="small_hire_content heading">
                <h3>WHAT CAN WE DO FOR YOU?</h3>
                <p>
                  We would be happy to discuss the project with you in person
                </p>
                <h4>
                  Call us on <Link to="tel:+18885054030">+1 888 505 4030</Link>
                </h4>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    DROP IN A MESSAGE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section" data-aos="fade-up" data-aos-duration="1500">
        <div className="container">
          <div className="heading">
            <h2 className="section-title">
              Hire Offshore Laravel Developers to Meet <br /> Custom
              Requirements
            </h2>
            <p className="section-subtitle pt-3">
              Elevate your web development projects with our offshore Laravel
              developers available for hire. Benefit from the efficiency and
              elegance of Laravel, a powerful PHP framework, for building robust
              and scalable web applications. Our developers bring expertise in
              crafting customized solutions tailored to your specific needs.
              Explore flexible engagement models designed to accommodate various
              project complexities and durations.
            </p>
          </div>

          <div className="row mb-5">
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-7.png" alt="" />
                <h6 className="details-heading">Custom Web App Development</h6>
                <p>
                  Experience bespoke solutions with our Laravel experts. Our
                  custom web app development combines Laravel's power and
                  precision for a unique and scalable digital experience.
                  Elevate your business with our proficiency. Contact us at to
                  discuss your project's individual needs and witness the
                  seamless integration of cutting-edge technology into your web
                  applications.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-8.png" alt="" />
                <h6 className="details-heading">API Integration</h6>
                <p className="">
                  Empower your web applications with our Laravel expertise in
                  API integration. Our developers ensure a seamless connection
                  between your Laravel-based system and external APIs, enhancing
                  functionality and data exchange. Whether integrating payment
                  gateways, third-party services, or social media APIs, we
                  provide efficient and secure solutions.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-9.png" alt="" />
                <h6 className="details-heading">Ajax Integration</h6>
                <p className="">
                  Transform your Laravel web applications into dynamic,
                  real-time experiences with our Ajax integration services. Our
                  experts seamlessly incorporate asynchronous requests, ensuring
                  instant data updates and enhanced user interaction.
                </p>
              </div>
            </div>

            <div className="col-md-6 py-2">
              <div className="bg-white req-card  ">
                <img src="assets/image/hire-angular-icon-10.png" alt="" />
                <h6 className="details-heading">Migration</h6>
                <p className="">
                  Upgrade your web application with our expert Laravel migration
                  services. Whether you're transitioning from older Laravel
                  versions or other frameworks, our team ensures a smooth and
                  efficient migration process. We specialize in preserving data
                  integrity, optimizing performance, and implementing the latest
                  features of Laravel.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white  req-card ">
                <img src="assets/image/hire-angular-icon-11.png" alt="" />
                <h6 className="details-heading">Support & Maintenance</h6>
                <p className="py-2">
                  Ensure the continuous optimal performance of your Laravel web
                  application with our dedicated support and maintenance
                  services. Our expert team is committed to resolving issues
                  promptly, applying updates, and proactively monitoring for
                  potential issues.
                </p>
              </div>
            </div>
            <div className="col-md-6 py-2">
              <div className="bg-white req-card ">
                <img src="assets/image/hire-angular-icon-12.png" alt="" />
                <h6 className="details-heading">Ecommerce Development</h6>
                <p className="py-2">
                  Transform your online business into a seamless and efficient
                  platform with our Laravel Ecommerce development services. Our
                  expert Laravel developers specialize in creating robust and
                  scalable Ecommerce solutions tailored to your unique business
                  needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="section bg_color"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="heading  text-center">
            <h2>Why Choose Us</h2>
          </div>
          <div className="row g-3 ">
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-13.png" alt="" />
                </div>
                <h5 className="my-3">Industry Experts</h5>
                <p>
                  Our in-house team is enriched with trusted and experienced
                  developers - no freelancer.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-14.png" alt="" />
                </div>
                <h5 className="my-3">Widely recommended</h5>
                <p>
                  We got hundreds of real 5-star ratings and reviews on Google,
                  GoodFirms, and Clutch combined.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-15.png" alt="" />
                </div>
                <h5 className="my-3">Customer Service</h5>
                <p>
                  Our zero complaint work approach helps us to ensure a seamless
                  customer experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="choose-us  text-center">
                <div className="choose-us-img">
                  <img src="assets/image/hire-angular-icon-16.png" alt="" />
                </div>
                <h5 className="my-3">Ideal Workplace</h5>
                <p>
                  We cultivate a productive work environment with a trendy setup
                  to enhance the outcomes.
                </p>
              </div>
            </div>
            <div className="row-below btn_sec text-center">
              <Link to="/contact" className="btn2 btn_gap">
                Get Started
              </Link>
              <Link to="/clients" className="btn2">
                Our Clients
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Slider />

      <Faq data={data} />
    </>
  );
}
