import React from "react";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
// import Team from "./Team";

export default function AboutUs() {
  return (
    <>
      <div className="inner-page" id="about-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="inner_content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h1>About Us </h1>
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="ri-home-4-line"></i> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about_sec">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="heading">
                <h2>
                  360 Digital Solutions <br /> for your needs.
                </h2>
                <p>
                  Adret Software Services is one of the leading companies in the
                  web and software development industry. We have a team of
                  seasoned skills and creative minds who are deft at providing
                  perfect web solutions to give you a competitive edge.
                </p>
                <p>
                  From developing responsive websites and user-friendly mobile
                  applications to establishing robust eCommerce platforms, our
                  multifaceted services aim to enhance your digital footprint.
                  Our strategic digital marketing initiatives encompass SEO,
                  social media, and content creation to drive brand visibility,
                  engagement, and conversions. Furthermore, our design and
                  branding services forge impactful visual identities, fostering
                  brand recognition and resonance
                </p>
                <div className="btn_sec">
                  <Link to="/contact" className="btn1">
                    Quick Enquiry
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <img
                src="assets/image/about-img1.png"
                className="img-fluid rounded"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec1 bg_color">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-4">
              <img
                src="assets/image/about-img2.png"
                className="img-fluid rounded"
                alt=""
              />
            </div>
            <div className="col-lg-8">
              <div className="heading">
                <h2>
                  Adret Software Services -<br /> Story Of a Leading Brand
                </h2>
                <p>
                  Embrace a comprehensive suite of 360-degree digital solutions
                  tailored to meet your diverse digital requisites. Our
                  integrated approach spans a wide spectrum of services,
                  encompassing web development, app creation, eCommerce
                  solutions, digital marketing strategies, and design and
                  branding expertise. We prioritize understanding your unique
                  needs, ensuring each solution aligns with your specific
                  objectives and amplifies your online presence. We're committed
                  to delivering innovative, high-quality solutions, providing a
                  one-stop destination for all your digital aspirations. Trust
                  us to propel your business forward in the digital realm,
                  enabling growth and success through our holistic and
                  client-focused 360 digital solutions.
                </p>
                <div className="btn_sec">
                  <Link to="/contact" className="btn2">
                    Drop In A Message
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_sec2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading">
                <h3>
                  Website Development | Mobile App Development <br /> | Digital
                  Marketing
                </h3>
                <p>
                  Boasting significant expertise in delivering impactful web and
                  mobile solutions, we empower our clients to expand their
                  businesses and drive substantial revenue. Our adept
                  professionals dedicate themselves to tailoring business
                  solutions for your industry niche, employing comprehensive
                  market research and competitor analysis for a customized
                  approach.
                </p>
              </div>
            </div>
          </div>
          <div className="row row-below g-4">
            <div className="col-lg-12">
              <div className="heading">
                <h4>Our Prime focal areas are:</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="content_about d-flex align-items-center ">
                <img
                  src="assets/image/code.gif"
                  className="img-fluid w-25 mr-3"
                  alt=""
                />
                <p>
                  <Link to="/website-design">
                    Website <br /> Development
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="content_about d-flex align-items-center ">
                <img
                  src="assets/image/ecommerce.gif"
                  className="img-fluid w-25 mr-3"
                  alt=""
                />
                <p>
                  <Link to="/ecommerce-web-design">eCommerce Development</Link>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="content_about d-flex align-items-center ">
                <img
                  src="assets/image/mobile-development.gif"
                  className="img-fluid w-25 mr-3"
                  alt=""
                />
                <p>
                  <Link to="/mobile-app-dev">
                    Mobile App <br /> Development
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="content_about d-flex align-items-center ">
                <img
                  src="assets/image/digital-marketing.gif"
                  className="img-fluid w-25 mr-3"
                  alt=""
                />
                <p>
                  <Link to="/digital-marketing">
                    Digital <br /> Marketing
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Slider />

      {/* <Team /> */}
    </>
  );
}
