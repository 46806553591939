import React from 'react';
import { Link } from 'react-router-dom';

export default function Services() {
  return (
    <>
        <section className="service_sec1 p-0">
        <video src={require('../../assets/video/Adret intro video.mp4')} autoPlay muted loop />
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 gaps">
                        <div className="heading">
                            <h2>Website, Mobile App And Digital Marketing Services</h2>
                           <p>
                            Adret Software is the one-stop where you can find excellent services to start your business with a bang digitally and get it ranked on the top in the search results. Apart from providing top-notch websites, mobile apps design and development, we can act as a game-changer for your business success. 
                           </p>
                            <div className="btn_sec">
                                <Link to="/contact" className="btn1 btn_gap">Quick Enquiry</Link>
                                <Link href="tel:+18885054030" className="btn2">Call Us : +1 888 505 4030</Link>                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="featured_service_sec bg_color1 " id="service_sec">
            <div className="container">
           
                <div className="row g-4">
               
                    <div className="col-lg-12">
                        <div className="sevice_card aos-init" data-aos="fade-up" data-aos-duration="3000">
                            <div className="row g-0 align-items-center">
                                <div className="col-sm-5  col-12">
                                    <div className="content_img">
                                        <img src="assets/image/service-webdesign-img1.png" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-12">
                                    <div className="content_left text-white">
                                        <h4>Website Design Services</h4>
                                        <p>
                                            Crafting captivating visual identities and messaging that resonate, strengthening brand recognition, trust, and differentiation, driving memorable and impactful audience connections.
                                        </p>
                                        <ul className="service_ul">
                                            <li><Link to="/ecommerce-web-design"> <i className="ri-arrow-right-double-line"></i>eCommerce Website Development</Link>
                                            </li>
                                            <li> <Link to="/web-app-developement"> <i className="ri-arrow-right-double-line"></i>Web Application Development</Link></li>
                                            <li><Link to="/wordpress-dev"> <i className="ri-arrow-right-double-line"></i>Wordpress Development</Link></li>
                                            <li><Link to="/small-business-website"> <i className="ri-arrow-right-double-line"></i>Small Business Website</Link></li>
                                            <li> <Link to="website-design"> <i className="ri-arrow-right-double-line"></i>Corporate Website</Link>
                                            </li>
                                           
                                        </ul>

                                        <div className="btn_sec mt-4">
                                            <Link to="/website-design" className="btn1">Know In Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="sevice_card aos-init" data-aos="fade-up" data-aos-duration="3000">
                            <div className="row g-0 align-items-center">
                               
                                <div className="col-sm-7 col-12 order-2 order-lg-1">
                                    <div className="content_left text-white">
                                        <h4>Mobile App Development</h4>
                                        <p>
                                            Crafting captivating visual identities and messaging that resonate, strengthening brand recognition, trust, and differentiation, driving memorable and impactful audience connections.
                                        </p>
                                        <ul className="service_ul">
                                            <li><Link to="/android-app-dev"> <i className="ri-arrow-right-double-line"></i>Android App development</Link>
                                            </li>
                                            <li> <Link to="/ios-app-dev"> <i className="ri-arrow-right-double-line"></i>iOS App Develoment</Link></li>
                                            <li><Link to="/hybrid-app-dev"> <i className="ri-arrow-right-double-line"></i>Hybrid App development</Link></li>

                        
                                        </ul>
                                        <div className="btn_sec mt-4">
                                            <Link to="/mobile-app-dev" className="btn1">Know In Detail</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5  col-12 order-1 order-lg-2">
                                    <div className="content_img">
                                        <img src="assets/image/mobile-app-service-img.png" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="sevice_card aos-init" data-aos="fade-up" data-aos-duration="3000">
                            <div className="row g-0 align-items-center">
                                <div className="col-sm-5  col-12">
                                    <div className="content_img">
                                        <img src="assets/image/digital-marketing-service-img1.jpg" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-12">
                                    <div className="content_left text-white">
                                        <h4>Digital Marketing Services</h4>
                                        <p>
                                            Crafting captivating visual identities and messaging that resonate, strengthening brand recognition, trust, and differentiation, driving memorable and impactful audience connections.
                                        </p>
                                        <ul className="service_ul">
                                            <li><Link to="/seo-services"> <i className="ri-arrow-right-double-line"></i>SEO Services</Link>
                                            </li>
                                            <li> <Link to="/social-media-marketing"> <i className="ri-arrow-right-double-line"></i>Social Media Marketing</Link></li>
                                            <li><Link to="/ppc-management"> <i className="ri-arrow-right-double-line"></i>PPC Management</Link></li>
                                            <li><Link to="/local-seo"> <i className="ri-arrow-right-double-line"></i>Local SEO Services</Link></li>
                                            <li> <Link to="/content-marketing"> <i className="ri-arrow-right-double-line"></i>Content Marketing Services</Link>
                                            </li>
                                           
                                        </ul>

                                        <div className="btn_sec mt-4">
                                            <Link to="/digital-marketing" className="btn1">Know In Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="sevice_card aos-init" data-aos="fade-up" data-aos-duration="3000">
                            <div className="row g-0 align-items-center">
                               
                                <div className="col-sm-7 col-12 order-2 order-lg-1">
                                    <div className="content_left text-white">
                                        <h4>eCommerce Website Development</h4>
                                        <p>
                                            Crafting captivating visual identities and messaging that resonate, strengthening brand recognition, trust, and differentiation, driving memorable and impactful audience connections.
                                        </p>
                                        <ul className="service_ul">
                                            <li><Link to="/magento-dev"> <i className="ri-arrow-right-double-line"></i>Magento Development</Link>
                                            </li>
                                            <li> <Link to="/shopify-dev"> <i className="ri-arrow-right-double-line"></i>Shopify Development</Link></li>
                                            <li><Link to="/big-commerce-dev"> <i className="ri-arrow-right-double-line"></i>BigCommerce</Link></li>
                                            <li><Link to="/woo-commerce-dev"> <i className="ri-arrow-right-double-line"></i>WooCommerce Development</Link></li>
                                            <li> <Link to="/open-cart-dev"> <i className="ri-arrow-right-double-line"></i>OpenCart Development</Link>
                                            </li>
                                           
                                        </ul>
                                        
                                        <div className="btn_sec mt-4">
                                            <Link to="/ecommerce-web-design" className="btn1">Know In Detail</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5  col-12 order-1 order-lg-2">
                                    <div className="content_img">
                                        <img src="assets/image/ecommerce-service-img.jpg" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="sevice_card aos-init" data-aos="fade-up" data-aos-duration="3000">
                            <div className="row g-0 align-items-center">
                                <div className="col-sm-5  col-12">
                                    <div className="content_img">
                                        <img src="assets/image/brand-wed-img.jpg" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-sm-7 col-12">
                                    <div className="content_left text-white">
                                        <h4>Brand Identity Design</h4>
                                        <p>
                                            Crafting captivating visual identities and messaging that resonate, strengthening brand recognition, trust, and differentiation, driving memorable and impactful audience connections.
                                        </p>
                                        <ul className="service_ul">
                                            <li><Link to="/logo-design"> <i className="ri-arrow-right-double-line"></i>Logo Design Services</Link>
                                            </li>
                                            <li> <Link to="/brochure-design"> <i className="ri-arrow-right-double-line"></i>Brochure Design Services</Link></li>
                                            <li><Link to="/corporate-identity"> <i className="ri-arrow-right-double-line"></i>Corporate Identity Design</Link></li>
                                            <li><Link to="/explainer-video"> <i className="ri-arrow-right-double-line"></i>Explainer Videos</Link></li>
                                            
                                           
                                        </ul>

                                        <div className="btn_sec mt-4">
                                            <Link to="/brand-identity" className="btn1">Know In Detail</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="sevice_card aos-init" data-aos="fade-up" data-aos-duration="3000">
                            <div className="row g-0 align-items-center">
                               
                                <div className="col-sm-7 col-12 order-2 order-lg-1">
                                    <div className="content_left text-white">
                                        <h4>Hire Web & Mobile Developers</h4>
                                        <p>
                                            Crafting captivating visual identities and messaging that resonate, strengthening brand recognition, trust, and differentiation, driving memorable and impactful audience connections.
                                        </p>
                                        <ul className="service_ul">
                                            <li><Link to="/hire-angular-dev"> <i className="ri-arrow-right-double-line"></i>Hire Angular Developer</Link>
                                            </li>
                                            <li> <Link to="/hire-node-dev"> <i className="ri-arrow-right-double-line"></i>Hire Node.js Developer</Link></li>
                                            <li><Link to="/hire-laravel-dev"> <i className="ri-arrow-right-double-line"></i>Hire Laravel Developer</Link></li>
                                            <li><Link to="/hire-php-dev"> <i className="ri-arrow-right-double-line"></i>Hire PHP Developer</Link></li>
                                            <li> <Link to="/hire-wordpress-dev"> <i className="ri-arrow-right-double-line"></i>Hire Wordpress Developer</Link>
                                            </li>
                                            <li> <Link to="/hire-android-dev"> <i className="ri-arrow-right-double-line"></i>Hire Android App Developer</Link>
                                            </li>
                                            <li> <Link to="/hire-ios-dev"> <i className="ri-arrow-right-double-line"></i>Hire IOS Developer</Link>
                                            </li>
                                            <li> <Link to="/hire-renative-dev"> <i className="ri-arrow-right-double-line"></i>Hire React Native Developer</Link>
                                            </li>
                                        </ul>
                                        
                                        <div className="btn_sec mt-4">
                                            <Link to="/contact" className="btn1">Know In Detail</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5  col-12 order-1 order-lg-2">
                                    <div className="content_img">
                                        <img src="assets/image/web-heir-img.jpg" alt="" className="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section className="logo_design_project p-0" id="android_app">
            <div className="row g-0 ">
                <div className="col-lg-5">
                    <div className="content_img aos-init aos-animate" data-aos="fade-right" data-aos-duration="2000">
                        <img src="assets/image/call-suprot-img.png" className="img-fluid" alt=""/>
                    </div>
                </div>
                <div className="col-lg-7 text-center">
                    <div className="heading aos-init aos-animate" data-aos="fade-left" data-aos-duration="2000">
                        <h3> Support For 24/7</h3>
                        <p>
                            What can we do for you?
We would be happy to discuss the project with you in person
                        </p>
                            <div className="btn_sec">
                                <Link to="/contact" className="btn2">Request a quote</Link>
                            </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="logo_design_sec">
            <div className="container">
                <div className="heading aos-init aos-animate" data-aos="fade-down" data-aos-duration="2000">
                    <h2>Why Choose Adret Software?</h2>
                    <p>
                        Entrust your Android app development needs to our experts. We assure you of:
                    </p>
                </div>
                <div className="row g-4 align-items-center">
                    <div className="col-lg-8">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="content_why aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="left_icon">
                                        <img src="assets/image/logo-design-icon1.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="content">
                                        <h5>Best Technologies</h5>
                                        <p>
                                            Our digital craftsmen are super developers who code to develop functional, manageable and scalable products.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content_why aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="left_icon">
                                        <img src="assets/image/logo-design-icon2.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="content">
                                        <h5>Reasonable Costs</h5>
                                        <p>
                                            We ensure you quality services at the most reasonable prices.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content_why aos-init" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="left_icon">
                                        <img src="assets/image/logo-design-icon3.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="content">
                                        <h5>Skilled Team</h5>
                                        <p>
                                            We have some really knowledgeable and skilled developers in our midst.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content_why aos-init" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="left_icon">
                                        <img src="assets/image/logo-design-icon4.png" alt="" className="img-fluid"/>
                                    </div>
                                    <div className="content">
                                        <h5>Extensive Experience</h5>
                                        <p>
                                            Our team has completed thousands of projects in 25+ countries worldwide.
                                            </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                    <div className="col-lg-4">
                        <img src="assets/image/android-app-development-img1.jpg" className="img-fluid rounded aos-init aos-animate" alt="" data-aos="zoom-out" data-aos-duration="2000"/>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
