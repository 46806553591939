import React, { useState } from "react";
import { AddContact } from "../../apis/ApiIntegrate";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { notificationConfig } from "../Notification/config";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "../Slider/Slider";

const filterButtons = [
  { id: 1, title: "Website" },
  { id: 2, title: "Mobile Apps" },
  { id: 3, title: "Logo" },
  // { id: 4, title: "Corporate Identity" },
  { id: 5, title: "Brochure" },
  { id: 6, title: "Explainer Videos" },
];

const filterImages = [
  {
    id: 1,
    src: "assets/image/portfolio/portfolio-1.webp",
    content: [
      {
        idx: 1,
        header: "Shree Balaji Textiles",
        desc1:
          "Product listings with search, user-friendly shopping cart, responsive design, product reviews, and secure payment gateway integration.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 2,
    src: "assets/image/portfolio/portfolio-2.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 3,
    src: "assets/image/portfolio/portfolio-3.webp",
    content: [
      {
        idx: 1,
        header: "Design Circle",
        desc1:
          "Portfolio Showcase, Project Galleries, Architectural Services Overview, Team Introduction, and Contact Information for an architectural website.",
        desc2: "Wordpress",
      },
    ],
    filterData: "Website",
  },
  {
    id: 4,
    src: "assets/image/portfolio/portfolio-4.webp",
    content: [
      {
        idx: 1,
        header: "KeraDermClinic",
        desc1:
          "Appointment Booking, Services Overview, Doctors' Profiles, Health Resources, and Contact Information for a clinic website",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 5,
    src: "assets/image/portfolio/portfolio-5.webp",
    content: [
      {
        idx: 1,
        header: "Holiday Resort",
        desc1:
          "Accommodation Showcase, Resort Amenities, Booking and Reservations, Destination Highlights, and Contact Information",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 6,
    src: "assets/image/portfolio/portfolio-6.webp",
    content: [
      {
        idx: 1,
        header: "Welcome to Gang-Dua",
        desc1:
          "Display of Lodging Options, Showcase of Resort Facilities, Reservation System, Highlights of Local Attractions, and Contact Details for a holiday resort website.",
        desc2: "WordPress",
      },
    ],
    filterData: "Website",
  },
  {
    id: 7,
    src: "assets/image/portfolio/portfolio-7.webp",
    content: [
      {
        idx: 1,
        header: "Conscious Academy",
        desc1:
          "Course Offerings, Faculty Profiles, Admissions Information, Student Resources, and Contact Details for an academy website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 8,
    src: "assets/image/portfolio/portfolio-8.webp",
    content: [
      {
        idx: 1,
        header: "Agrive Export & Import",
        desc1:
          "Product Catalog, Export and Import Services, Global Market Insights, Logistics Solutions, and Contact Information for an agricultural export and import website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 9,
    src: "assets/image/portfolio/portfolio-9.webp",
    content: [
      {
        idx: 1,
        header: "Glacier's Exports",
        desc1:
          "Marble Product Showcase, Export Services, Quality Assurance, Global Distribution Network, and Contact Information for a marble export website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 10,
    src: "assets/image/portfolio/portfolio-10.webp",
    content: [
      {
        idx: 1,
        header: "Safety Air Cushions  ",
        desc1:
          "Security Solutions Showcase, Installation Services, Customized Packages, Client Testimonials, and Contact Information for a security system installation service website.",
        desc2: "WordPress",
      },
    ],
    filterData: "Website",
  },
  {
    id: 11,
    src: "assets/image/portfolio/portfolio-11.webp",
    content: [
      {
        idx: 1,
        header: "Window King",
        desc1:
          "Window Cleaning Services, Service Packages, Safety Measures, Customer Testimonials, and Contact Information for a window cleaning service website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 12,
    src: "assets/image/portfolio/portfolio-12.webp",
    content: [
      {
        idx: 1,
        header: "Paver Sealing Pros",
        desc1:
          "Paver Sealing Services, Process Overview, Before-and-After Showcases, Client Testimonials, and Contact Information for a paver sealing service website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 13,
    src: "assets/image/portfolio/portfolio-13.webp",
    content: [
      {
        idx: 1,
        header: "Manjushree",
        desc1:
          "Industrial Factory Solutions Showcase, Process Optimization Services, Customized Solutions, Industry Insights, and Contact Information for an industrial factorial solutions website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 14,
    src: "assets/image/portfolio/portfolio-14.webp",
    content: [
      {
        idx: 1,
        header: "Allister Biotech",
        desc1:
          "Pharmaceutical Products Showcase, Research and Development, Quality Assurance, Global Distribution, and Contact Information for a pharmaceutical company website.",
        desc2: "WordPress",
      },
    ],
    filterData: "Website",
  },
  {
    id: 15,
    src: "assets/image/portfolio/portfolio-15.webp",
    content: [
      {
        idx: 1,
        header: "Makeover By Nabanita",
        desc1:
          "Makeover Services Portfolio, Before-and-After Transformations, Beauty Packages, Style Tips, and Contact Information for a makeover website",
        desc2: "WordPress",
      },
    ],
    filterData: "Website",
  },
  {
    id: 16,
    src: "assets/image/portfolio/portfolio-16.webp",
    content: [
      {
        idx: 1,
        header: "Ayojon Caterers",
        desc1:
          "Catering Services Showcase, Menu Options, Event Packages, Client Testimonials, and Contact Information for a catering website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 17,
    src: "assets/image/portfolio/portfolio-17.webp",
    content: [
      {
        idx: 1,
        header: "AsiaTripHolidays",
        desc1:
          "Travel Destinations Showcase, Vacation Packages, Booking Services, Travel Guides, and Contact Information for a tour and travel website",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 18,
    src: "assets/image/portfolio/portfolio-18.webp",
    content: [
      {
        idx: 1,
        header: "ChildiT",
        desc1:
          "Child Care Services Overview, Curriculum and Activities, Parent Resources, Staff Profiles, and Contact Information for a child care center website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 19,
    src: "assets/image/portfolio/portfolio-19.webp",
    content: [
      {
        idx: 1,
        header: "DIIHM Kolkata",
        desc1:
          "Hospitality and Hospital Management University Website: Academic Programs, Industry-Relevant Curriculum, Research Initiatives, Faculty Profiles, and Admission Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 20,
    src: "assets/image/portfolio/portfolio-20.webp",
    content: [
      {
        idx: 1,
        header: "Reid Security Solutions",
        desc1:
          "Comprehensive Services Showcase, Customized Security Plans, Industry Expertise, Client Testimonials, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 21,
    src: "assets/image/portfolio/portfolio-21.webp",
    content: [
      {
        idx: 1,
        header: "Safai Mates",
        desc1:
          "Cleaning Services Website: Service Offerings, Customized Cleaning Plans, Eco-Friendly Practices, Client Testimonials, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 22,
    src: "assets/image/portfolio/portfolio-22.webp",
    content: [
      {
        idx: 1,
        header: "Satyam Marbles",
        desc1:
          "Exquisite Collection Showcase, Customization Options, Quality Assurance, Global Shipping, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 23,
    src: "assets/image/portfolio/portfolio-23.webp",
    content: [
      {
        idx: 1,
        header: "Secure Life Healthcare",
        desc1:
          "Comprehensive Healthcare Offerings, Specialized Departments, Patient Resources, Medical Professionals, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },
  {
    id: 24,
    src: "assets/image/portfolio/portfolio-24.webp",
    content: [
      {
        idx: 1,
        header: "S.I.E.T",
        desc1:
          " Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Website",
  },

  // website end

  {
    id: 25,
    src: "assets/image/portfolio/logo-1.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 26,
    src: "assets/image/portfolio/logo-2.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 27,
    src: "assets/image/portfolio/logo-3.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 28,
    src: "assets/image/portfolio/logo-4.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 29,
    src: "assets/image/portfolio/logo-5.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 30,
    src: "assets/image/portfolio/logo-6.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 31,
    src: "assets/image/portfolio/logo-7.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 32,
    src: "assets/image/portfolio/logo-8.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  {
    id: 33,
    src: "assets/image/portfolio/logo-9.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },
  // {
  //   id: 34,
  //   src: "assets/image/portfolio/logo-10.webp",
  //   content: [
  //     {
  //       idx: 1,
  //       header: "Sher-e-Bengal",
  //       desc1:
  //         "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
  //       desc2: "HTML, CSS, JS",
  //     },
  //   ],
  //   filterData: "Logo",
  // },
  // {
  //   id: 35,
  //   src: "assets/image/portfolio/logo-11.webp",
  //   content: [
  //     {
  //       idx: 1,
  //       header: "Sher-e-Bengal",
  //       desc1:
  //         "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
  //       desc2: "HTML, CSS, JS",
  //     },
  //   ],
  //   filterData: "Logo",
  // },
  {
    id: 36,
    src: "assets/image/portfolio/logo-12.webp",
    content: [
      {
        idx: 1,
        header: "Sher-e-Bengal",
        desc1:
          "Menu display, reservation system, online ordering, high-quality food imagery, and contact information for a restaurant website.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Logo",
  },

  // logo end
  {
    id: 40,
    src: "assets/image/portfolio/brochure-2.webp",
    content: [
      {
        idx: 1,
        header: "Brochure",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Brochure",
  },
  {
    id: 41,
    src: "assets/image/portfolio/brochure-3.jpg",
    content: [
      {
        idx: 1,
        header: "Brochure",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Brochure",
  },
  {
    id: 42,
    src: "assets/image/portfolio/brochure-4.jpg",
    content: [
      {
        idx: 1,
        header: "Brochure",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Brochure",
  },
  {
    id: 43,
    src: "assets/image/portfolio/brochure-5.jpg",
    content: [
      {
        idx: 1,
        header: "Brochure",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Brochure",
  },
  {
    id: 44,
    src: "assets/image/portfolio/brochure-1.jpg",
    content: [
      {
        idx: 1,
        header: "Brochure",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Brochure",
  },

  // brochure end
  {
    id: 60,
    src: "assets/image/portfolio/explainer-video-1.mp4",
    content: [
      {
        idx: 1,
        header: "Restaurant Management System",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Explainer Videos",
  },
  {
    id: 61,
    src: "assets/image/portfolio/explainer-2.webm",
    content: [
      {
        idx: 1,
        header: "Restaurant Management System",
        desc1:
          "Academic Programs, Faculty Profiles, Training Courses, Student Resources, and Contact Information.",
        desc2: "HTML, CSS, JS",
      },
    ],
    filterData: "Explainer Videos",
  },

  // videos end
  {
    id: 80,
    src: "assets/image/portfolio/mobile-app-1.png",
    content: [
      {
        idx: 1,
        header: "Mehendi Ecommerce",
        desc1:
          "Curated Products, Customization Options, Secure Checkout, and User Account Management.",
        desc2: "Kotlin",
      },
    ],
    filterData: "Mobile Apps",
  },
  {
    id: 81,
    src: "assets/image/portfolio/mobile-app-2.png",
    content: [
      {
        idx: 1,
        header: "Restaurant Management Software",
        desc1:
          "Curated Products, Customization Options, Secure Checkout, and User Account Management.",
        desc2: "HTML, CSS, JS, PHP",
      },
    ],
    filterData: "Mobile Apps",
  },
  {
    id: 82,
    src: "assets/image/portfolio/mobile-app-31.png",
    content: [
      {
        idx: 1,
        header: "Restaurant Management Software",
        desc1:
          "Curated Products, Customization Options, Secure Checkout, and User Account Management.",
        desc2: "HTML, CSS, JS, PHP",
      },
    ],
    filterData: "Mobile Apps",
  },
  // {
  //   id: 81,
  //   src: "assets/image/portfolio/mobile-app-1.png",
  //   content: [
  //     {
  //       idx: 1,
  //       header: "Mehendi Ecommerce",
  //       desc1:
  //         "Curated Products, Customization Options, Secure Checkout, and User Account Management.",
  //       desc2: "Kotlin",
  //     },
  //   ],
  //   filterData: "Mobile Apps",
  // },
  // {
  //   id: 82,
  //   src: "assets/image/portfolio/mobile-app-1.png",
  //   content: [
  //     {
  //       idx: 1,
  //       header: "Mehendi Ecommerce",
  //       desc1:
  //         "Curated Products, Customization Options, Secure Checkout, and User Account Management.",
  //       desc2: "Kotlin",
  //     },
  //   ],
  //   filterData: "Mobile Apps",
  // },
  ,
];

const Portfolio = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    business: "",
    other: "",
    category: "",
    country: "",
    message: "",
  });

  const [othContainer, setOthContainer] = useState(false);
  const handleChangeContainer = (e) => {
    const { name, value } = e.target;

    if (value == "Others") {
      setOthContainer(true);
    } else {
      setOthContainer(false);
      setData((prevData) => ({ ...prevData, ["other"]: "" }));
    }

    setData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    business: "",
    other: "",
    category: "",
    country: "",
    message: "",
  });
  const handleChangeOther = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = "Name is required";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(data.email)) {
      errors.email = "Invalid email format";
    }

    // Validate mobile
    if (!data.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    }

    // Validate category
    if (!data.category.trim()) {
      errors.category = "Category is required";
    }
    if (data.category == "Others") {
      if (!data.other.trim()) {
        errors.other = "Other is required";
      }
    }
    // Validate message
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data)
    setIsLoading(true);
    const isValid = validateForm();
    if (isValid) {
      try {
        const response = await axios.post(
          "https://project.adretsoftware.in/adret_apis/add-contact.php",
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        setIsLoading(false);
        if (response.data === 200) {
          setOthContainer(false);
          setData({
            name: "",
            email: "",
            mobile: "",
            other: "",
            business: "",
            category: "",
            country: "",
            message: "",
          });

          setValidationErrors({});
          toast.success("Request Submitted");
        } else {
          toast(response.data, notificationConfig.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsLoading(false);
  };

  // Submit Contact Form End

  const [selectedFilter, setSelectedFilter] = useState(1);

  const handleFilterClick = (filterId) => {
    setSelectedFilter(filterId);
  };

  const filteredImages = selectedFilter
    ? filterImages.filter(
        (image) =>
          image.filterData ===
          filterButtons.find((button) => button.id === selectedFilter).title
      )
    : filterImages;

  return (
    <div>
      <>
        <section className="portfolio_sec">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-7">
                {/* <div
                  className="protfolio_img"
                  data-aos="zoom-in"
                  data-aos-duration={2000}
                >
                  <img
                    src="assets/image/portfolio-img.png"
                    alt=""
                    className="img-fluid"
                  />
                </div> */}
                <div
                  className="d-flex flex-column justify-content-start"
                  style={{ height: 500 }}
                >
                  <div
                    className="d-flex flex-column mb-5"
                    style={{ position: "relative", zIndex: 9 }}
                  >
                    <p style={{ color: "white", margin: 0 }}>
                      <b>Get Digital Services</b>
                    </p>
                    <a className="btn1 smallfont" href="tel:+18885054030">
                      Customer Helpline : +1 888 505 4030
                    </a>
                  </div>
                  <div className="portfolioOverlay" />
                  <div style={{ position: "relative", zIndex: 9 }}>
                    <h3 className="blueHLText">
                      {" "}
                      #1 Digital Marketing Company In Salt Lake
                    </h3>
                    {/* <p className="blueHLText">
                      Burning Money in lead generation stil Not able to get
                      Relevant Leads?
                    </p>
                    <p className="blueHLText">Lead flow is Not Consistent?</p>
                    <p className="blueHLText">
                      No Lead Nurturing or follow up system?
                    </p>
                    <h3 className="blueHLText">
                      {" "}
                      Get More Traffic with our Comprehensive Digital Marketing
                      Services
                    </h3> */}
                    <p className="blueHLText">
                      Digital Services Provided By Us -
                    </p>
                    <div className="row pb-3">
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        SOFTWARE{" "}
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        WEBSITE DESIGN & DEVELOPMENT
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        {" "}
                        GRAPHIC DESIGN
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        {" "}
                        UI/UX DESIGN{" "}
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        ECOMMERCE DEVELOPMENT
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        {" "}
                        MOBILE APP DEVELOPMENT
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        {" "}
                        GAME DEVELOPMENT{" "}
                      </div>
                      <div
                        className="col-6 py-2 blueHLText"
                        style={{ fontSize: 14, whiteSpace: "nowrap" }}
                      >
                        {" "}
                        DIGITAL MARKETING
                      </div>
                    </div>
                    <h6 className="blueHLText">
                      Adret Software is Official GOOGLE PARTNER Company in
                      United States
                    </h6>
                    <p />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="row g-3 mb-2 justify-content-start">
                  <div className="col-lg-12 col-md-12">
                    {/* <div
                      className="contact-top"
                      data-aos="fade-left"
                      data-aos-duration={2000}
                    >
                      <div className="contact-icon">
                        <img src="assets/image/phone.gif" alt="" />
                      </div>
                      <div className="contact-info-top">
                        <h4>Call Us</h4>
                        <p className="mb-0">
                          <Link to="tel:+18885054030" class="text-white">
                            +1 888 505 4030
                          </Link>
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="contact-form"
                  data-aos="fade-left"
                  data-aos-duration={2000}
                >
                  <form>
                    <h4>Get A Quote</h4>
                    <div className="row g-3">
                      <div className="col-md-12 portfolio-contact">
                        <input
                          type="text"
                          name="name"
                          id=""
                          value={data.name}
                          placeholder="Enter your Name"
                          onChange={(e) => handleChange(e)}
                        />
                        <span className="portfolio-contact-error">
                          {validationErrors.name && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}{" "}
                          {validationErrors.name}
                        </span>
                      </div>
                      <div className="col-md-12 portfolio-contact">
                        <input
                          type="email"
                          name="email"
                          id=""
                          value={data.email}
                          placeholder="Enter your E-mail id"
                          onChange={(e) => handleChange(e)}
                        />
                        <span className="portfolio-contact-error">
                          {validationErrors.email && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}{" "}
                          {validationErrors.email}
                        </span>
                      </div>
                      <div className="col-md-12 col-sm-12 portfolio-contact">
                        <input
                          type="text"
                          name="mobile"
                          id=""
                          value={data.mobile}
                          placeholder="Enter your Phone Number"
                          onChange={(e) => handleChange(e)}
                        />
                        <span className="portfolio-contact-error">
                          {validationErrors.mobile && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}{" "}
                          {validationErrors.mobile}
                        </span>
                      </div>
                      <div className="col-md-12 col-sm-12 portfolio-contact">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="category"
                          onChange={(e) => handleChangeContainer(e)}
                          value={data.category}
                        >
                          <option value="">--Select Category--</option>
                          <option value="SOFTWARE">SOFTWARE</option>
                          <option value="WEBSITE DESIGN">WEBSITE DESIGN</option>
                          <option value="WEBSITE DEVELOPMENT">
                            WEBSITE DEVELOPMENT
                          </option>
                          <option value="GRAPHIC DESIGN">GRAPHIC DESIGN</option>
                          <option value="UI/UX DESIGNING">
                            UI/UX DESIGNING
                          </option>
                          <option value="ECOMMERCE DEVELOPMENT">
                            ECOMMERCE DEVELOPMENT
                          </option>
                          <option value="MOBILE APP DEVELOPMENT">
                            MOBILE APP DEVELOPMENT
                          </option>
                          <option value="GAME DEVELOPMENT">
                            GAME DEVELOPMENT
                          </option>
                          <option value="DIGITAL MARKETING">
                            DIGITAL MARKETING
                          </option>
                          <option value="Others">Others</option>
                        </select>
                        <span className="portfolio-contact-error">
                          {validationErrors.category && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}
                          {validationErrors.category}
                        </span>
                      </div>
                      {othContainer && (
                        <div className="col-12 portfolio-contact">
                          <input
                            type="text"
                            name="other"
                            id=""
                            value={data.other}
                            placeholder="Enter other details"
                            onChange={(e) => handleChangeOther(e)}
                          />
                          <span className="portfolio-contact-error">
                            {validationErrors.other && (
                              <i className="fa-solid fa-triangle-exclamation"></i>
                            )}{" "}
                            {validationErrors.other}
                          </span>
                        </div>
                      )}
                      <div className="col-md-12 col-sm-12 portfolio-contact">
                        <select
                          id="country"
                          name="country"
                          class="form-control"
                          onChange={(e) => handleChangeContainer(e)}
                          value={data.country}
                        >
                          <option value="">--Select Country--</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Åland Islands">Åland Islands</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos (Keeling) Islands">
                            Cocos (Keeling) Islands
                          </option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, The Democratic Republic of The">
                            Congo, The Democratic Republic of The
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote D'ivoire">Cote D'ivoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands (Malvinas)">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-bissau">Guinea-bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard Island and Mcdonald Islands">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="Holy See (Vatican City State)">
                            Holy See (Vatican City State)
                          </option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran, Islamic Republic of">
                            Iran, Islamic Republic of
                          </option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jersey">Jersey</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="Korea, Republic of">
                            Korea, Republic of
                          </option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libyan Arab Jamahiriya">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option value="Macedonia, The Former Yugoslav Republic of">
                            Macedonia, The Former Yugoslav Republic of
                          </option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia, Federated States of">
                            Micronesia, Federated States of
                          </option>
                          <option value="Moldova, Republic of">
                            Moldova, Republic of
                          </option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montenegro">Montenegro</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Netherlands Antilles">
                            Netherlands Antilles
                          </option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestinian Territory, Occupied">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russian Federation">
                            Russian Federation
                          </option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and The Grenadines">
                            Saint Vincent and The Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="South Georgia and The South Sandwich Islands">
                            South Georgia and The South Sandwich Islands
                          </option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen">
                            Svalbard and Jan Mayen
                          </option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syrian Arab Republic">
                            Syrian Arab Republic
                          </option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                          </option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-leste">Timor-leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="United States Minor Outlying Islands">
                            United States Minor Outlying Islands
                          </option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Viet Nam">Viet Nam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.S.">
                            Virgin Islands, U.S.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                        <span className="portfolio-contact-error">
                          {validationErrors.country && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}{" "}
                          {validationErrors.country}
                        </span>
                      </div>
                      <div class="col-md-12 col-sm-12 portfolio-contact">
                        <input
                          type="text"
                          name="business"
                          id=""
                          value={data.business}
                          placeholder="Enter your Business Name"
                          onChange={(e) => handleChange(e)}
                          className="mb-1"
                        />
                        <span className="portfolio-contact-error">
                          {validationErrors.business && (
                            <i class="fa-solid fa-triangle-exclamation"></i>
                          )}
                          {validationErrors.business}
                        </span>
                      </div>
                      <div className="col-12 portfolio-contact">
                        <textarea
                          name="message"
                          id=""
                          cols={30}
                          placeholder="Message"
                          value={data.message}
                          rows={2}
                          onChange={(e) => handleChange(e)}
                        />
                        <span className="portfolio-contact-error">
                          {validationErrors.message && (
                            <i className="fa-solid fa-triangle-exclamation"></i>
                          )}{" "}
                          {validationErrors.message}
                        </span>
                      </div>
                      <div className="col-12">
                        <button onClick={handleSubmit}>
                          {isLoading && (
                            <span
                              className="spinner-grow spinner-grow-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          Submit now
                        </button>
                        <a href="tel:+18885054030" className="callUsBtn">
                          Call Us
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="portfolio_sec1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ul
                  className="nav nav-pills mb-3 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  {filterButtons.map((elem) => {
                    const { id, title } = elem;
                    return (
                      <li className="nav-item" role="presentation" key={id}>
                        <button
                          className={`nav-link ${
                            selectedFilter === id ? "active" : ""
                          }`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${title}`}
                          onClick={() => handleFilterClick(id)}
                        >
                          {title}
                        </button>
                      </li>
                    );
                  })}
                </ul>
                <div
                  className="heading"
                  data-aos="fade-up"
                  data-aos-duration={2000}
                >
                  <h4 className="section-title-2 py-5">
                    Robust, functional &amp; cost-effective services for your
                    business
                  </h4>
                </div>
              </div>

              <div>
                <div className="row g-4">
                  {filteredImages.map((data) => {
                    const { id, src, content } = data;
                    const { idx, header, desc1, desc2 } = content[0];

                    return (
                      <div
                        className={` ${
                          id >= 25 && id <= 36
                            ? `col-lg-3 col-md-3 col-sm-4`
                            : "col-lg-4 col-md-4 col-sm-6"
                        }`}
                        key={id}
                      >
                        <div
                          className="content_portfolio"
                          data-aos="zoom-in"
                          data-aos-duration={2000}
                          style={
                            id >= 80
                              ? {
                                  height: "fit-content",
                                  boxShadow: "none",
                                  width: "200px",
                                  margin: "0 auto",
                                }
                              : {}
                          }
                        >
                          <div className="portfolio_img">
                            {60 <= id && id <= 79 ? (
                              <video
                                width="350"
                                height="250"
                                controls
                                autoPlay
                                muted
                                loop
                              >
                                <source src={src} type="video/mp4" />
                              </video>
                            ) : (
                              <img
                                src={src}
                                className="img-fluid"
                                alt=""
                                style={
                                  id >= 25 && id <= 30
                                    ? {
                                        objectFit: "contain",
                                        width: "75%",
                                        margin: "auto",
                                      }
                                    : {}
                                }
                              />
                            )}
                          </div>

                          <div
                            className={`overlay_dec ${
                              25 <= id && id <= 79 ? "d-none" : ""
                            }`}
                            key={idx}
                          >
                            <h5>{header}</h5>
                            <ul className="list-unstyled">
                              <li>
                                <i className="ri-arrow-right-double-fill" />{" "}
                                {desc1}
                              </li>
                              <li>
                                <i className="ri-arrow-right-double-fill" />{" "}
                                {desc2}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
        <Slider />
      </>
    </div>
  );
};

export default Portfolio;
